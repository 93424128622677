import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomizationProduct, ProductsState } from 'typings/Products';
import { getDefaultSliceCustomizations } from 'utils/intake/productCustomizationUtils';
import {
  fetchAllProductGroups,
  fetchAllProductOptions,
  fetchAllProductSubtypes,
  fetchBestSellerProducts,
  fetchCategoriesWithProducts,
  fetchProductOptionPrices,
  getCustomizationProductSettings,
  getCustomizedItemPrice,
} from './products.thunk-actions';

const initialState: ProductsState = {
  activeCategoryCode: '',
  activeOptionId: null,
  activeSubOptionId: null,
  groups: [],
  options: [],
  subOptions: [],
  products: [],
  categories: [],
  productOptionPrices: [],
  bestSellerProducts: [],
};

const productsSlice = createSlice({
  name: '[PRODUCTS]',
  initialState,
  reducers: {
    resetProductsState: () => initialState,
    clearActiveProductOption: (state) => ({
      ...state,
      activeOptionId: null,
      activeSubOptionId: null,
    }),
    setActiveProductCategory: (state, action: PayloadAction<string>) => ({
      ...state,
      activeCategoryCode: action.payload,
      activeSubOptionId: null,
      activeOptionId: null,
    }),
    setActiveProductOption: (state, action: PayloadAction<number>) => ({
      ...state,
      activeOptionId: action.payload,
    }),
    setActiveProductSubOption: (state, action: PayloadAction<number>) => ({
      ...state,
      activeSubOptionId: action.payload,
      activeOptionId: null,
    }),
    setCustomizeProduct: (state, action: PayloadAction<CustomizationProduct | undefined>) => ({
      ...state,
      productToCustomize: action.payload
        ? {
            ...action.payload,
            sliceCustomizations:
              action.payload?.sliceCustomizations ?? getDefaultSliceCustomizations(action.payload),
          }
        : undefined,
    }),
    clearCustomizationProductPrice: (state) => ({
      ...state,
      customizationProductPrice: undefined,
    }),
  },
  extraReducers(builder) {
    builder.addCase(fetchAllProductGroups.fulfilled, (state, action) => ({
      ...state,
      groups: action.payload,
    }));
    builder.addCase(fetchAllProductOptions.fulfilled, (state, action) => ({
      ...state,
      options: action.payload,
    }));

    builder.addCase(fetchAllProductSubtypes.fulfilled, (state, action) => ({
      ...state,
      subOptions: action.payload,
    }));
    builder.addCase(fetchBestSellerProducts.fulfilled, (state, action) => ({
      ...state,
      bestSellerProducts: action.payload,
    }));
    builder.addCase(fetchCategoriesWithProducts.fulfilled, (state, action) => ({
      ...state,
      products: action.payload.productsWithOptions,
      categories: action.payload.sortedFilledCategories,
    }));
    builder.addCase(fetchProductOptionPrices.fulfilled, (state, action) => ({
      ...state,
      productOptionPrices: action.payload,
    }));
    builder.addCase(getCustomizationProductSettings.fulfilled, (state, action) => ({
      ...state,
      productToCustomize: action.payload,
    }));
    builder.addCase(getCustomizedItemPrice.pending, (state, action) => ({
      ...state,
      customizationProductPrice: undefined,
    }));
    builder.addCase(getCustomizedItemPrice.fulfilled, (state, action) => ({
      ...state,
      customizationProductPrice: action.payload,
    }));
    builder.addCase(getCustomizedItemPrice.rejected, (state, action) => ({
      ...state,
      customizationProductPrice: undefined,
    }));
  },
});

const { reducer } = productsSlice;
export const {
  resetProductsState,
  clearActiveProductOption,
  setActiveProductCategory,
  setActiveProductOption,
  setActiveProductSubOption,
  setCustomizeProduct,
  clearCustomizationProductPrice,
} = productsSlice.actions;

export default reducer;
