import jwtDecode from 'jwt-decode';
import { DecodedToken } from 'typings/Auth';
import generateCodeChallenge from './cryptography';

export const prepareCodeChallenge = (): void => {
  const { codeChallenge, codeVerifier } = generateCodeChallenge();
  localStorage.setItem('code_verifier', codeVerifier);
  localStorage.setItem('code_challenge', codeChallenge);
};

export const destroyCodeChallenge = (): void => {
  localStorage.removeItem('code_verifier');
  localStorage.removeItem('code_challenge');
};
