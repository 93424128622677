import posApi, { posApiUrls } from 'API/PosApi';
import {
  AvailableIntakeContents,
  DineInOrderStatus,
  IntakeStatuses,
  PAGE_SIZE_FOR_DINE_IN_FLOORS,
  PickUpTypesValues,
} from 'containers/Intake/IntakeConsts';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import { isValidAxiosResponse } from 'typings/type-guards';
import { CustomizationProduct } from 'typings/Products';
import { Coupon } from 'typings/Coupons';
import { createInitialCustomization } from 'utils/intake/productSetUtils';
import { recalculateBasket, removeCouponFromTheBasket } from 'stores/Basket/basket.thunk-actions';
import { clearBasket, doNotGroupItems, ignoreItemsInPriceCalculations } from 'stores/Basket/basket.slice';
import { generateBasketItemsFromDineInOrder } from 'utils/intake/basketUtils';
import { DineInItem } from 'typings/Basket';
import { getOrderHistory } from 'stores/Intake/intake.thunk-actions';
import {
  restartIntakeState,
  setActiveDeliveryType,
  setActiveIntakeTab,
  setIntakeStatus,
  setOrderRemarks,
} from 'stores/Intake/intake.slice';
import {
  clearSelectedDineInOrder,
  selectDineInOrder,
  setCreateDineInOrderStatus,
} from 'stores/DineIn/dineIn.slice';
import { getAppInsights } from 'App/AppInitializationWrapper/AppInitializationWrapper';
import { setCustomizeProduct } from 'stores/Products/products.slice';
import { restartOrder } from 'stores/combined.actions';
import { generateFilledCouponsFromDineInOrder } from './DineInUtils';
import {
  RequestNewTab,
  DineInOrdersPage,
  RequestFinalizeDineInOrder,
  DineInOrderCreationResult,
  DineInOrder,
  DineInBasketItemDto,
  RequestPlaceUnpaidDineInOrder,
} from './dineInTypes';

export const fetchFilteredDineInOrders = createAsyncThunk(
  '[DINEIN]/fetchFilteredDineInOpenOrders',
  async (_, { getState }) => {
    const { dineInFilters } = (getState() as RootState).dineIn;

    const result = await posApi.get<DineInOrdersPage>(
      posApiUrls.DINEIN_ORDERS_PAGE(
        dineInFilters.orderStatus,
        `${dineInFilters.orderBy}.${dineInFilters.sortingOrder}`,
        dineInFilters.rowIndex,
        dineInFilters.rowsPerPage,
      ),
    );
    return result.data;
  },
);

export const fetchAllDineInOpenOrders = createAsyncThunk('[DINEIN]/fetchAllDineInOpenOrders', async () => {
  const result = await posApi.get<DineInOrdersPage>(
    posApiUrls.DINEIN_ORDERS_PAGE(DineInOrderStatus.Opened, '', 0, PAGE_SIZE_FOR_DINE_IN_FLOORS),
  );
  return result.data;
});

export const openNewTable = createAsyncThunk<void, string, { state: RootState }>(
  '[DINEIN]/openNewTab',
  async (tableName, { dispatch }) => {
    dispatch(clearBasket());
    dispatch(setCustomizeProduct());
    dispatch(setOrderRemarks());
    dispatch(setActiveIntakeTab(AvailableIntakeContents.Products));
    const newDineInOrder = {
      id: '',
      tableIdentifier: tableName,
      items: [],
      coupons: [],
      iterationsHistory: [],
      status: DineInOrderStatus.Opened,
      modificationTimestamp: new Date().toString(),
      numberOfGuests: 0,
      total: 0,
    };
    dispatch(selectDineInOrder(newDineInOrder));
    dispatch(setActiveDeliveryType(PickUpTypesValues.dineIn));
    dispatch(setIntakeStatus(IntakeStatuses.productsSection));
    dispatch(
      recalculateBasket({
        requestedBasket: [],
        coupons: [],
      }),
    );
  },
);

export const postNewTab = createAsyncThunk<DineInOrderCreationResult, RequestNewTab, { state: RootState }>(
  '[DINEIN]/postNewTab',
  async (params, { dispatch }) => {
    dispatch(setCreateDineInOrderStatus({ status: 'PENDING' }));

    const result = await posApi.post<DineInOrderCreationResult>(
      `${posApiUrls.DINEIN_ORDERS}?tableIdentifier=${params.tableIdentifier}`,
    );

    if (!result || (result.status !== 201 && result.status !== 200)) {
      throw Error('Error creating new tab');
    }

    dispatch(restartIntakeState());
    dispatch(clearSelectedDineInOrder());

    if (isValidAxiosResponse(result)) {
      dispatch(clearBasket());
      await dispatch(getDineInOrder(result.data.orderId));
    }

    return result.data;
  },
);

export const postFinalizeDineInOrder = createAsyncThunk<
  { value: string; shouldClear: boolean },
  RequestFinalizeDineInOrder,
  { state: RootState }
>(
  '[DINEIN]/postFinalizeDineInOrder',
  async ({ tabId, newOrderId, config, shouldClear = true, refreshConfig }, { dispatch }) => {
    const appInsights = getAppInsights();
    appInsights.trackEvent({
      name: 'Submit dine-in order',
      properties: {
        orderId: newOrderId,
        tabId,
        config,
        refreshConfig,
      },
    });

    const result = await posApi.post<string>(
      posApiUrls.DINEIN_ORDER_FINALIZE(tabId, newOrderId),
      JSON.stringify(config),
    );

    if (!result || result.status !== 200) {
      throw Error('Error placing unpaid dine in!');
    }

    dispatch(clearSelectedDineInOrder());
    await dispatch(getOrderHistory(result.data));

    if (refreshConfig) {
      //
      dispatch(fetchFilteredDineInOrders());
    }

    dispatch(restartOrder());
    return { value: result.data, shouldClear };
  },
);

export const postPlaceUnpaidDineInOrder = createAsyncThunk<
  { value: string },
  RequestPlaceUnpaidDineInOrder,
  { state: RootState }
>('[DINEIN]/postPlaceUnpaidDineInOrder', async ({ tabId, newOrderId, manualPriceOverride }, { dispatch }) => {
  const appInsights = getAppInsights();

  appInsights.trackEvent({
    name: 'Placing unpaid dine-in order',
    properties: {
      orderId: newOrderId,
      tabId,
      manualPriceOverride,
    },
  });

  const result = await posApi.post<string>(
    posApiUrls.DINEIN_ORDER_FINALIZE_V2(tabId),
    JSON.stringify({ manualPriceOverride, orderId: newOrderId }),
  );

  if (!result || result.status !== 200) {
    throw Error('Error finalizing dine in order!');
  }

  dispatch(clearSelectedDineInOrder());
  await dispatch(getOrderHistory(result.data));

  dispatch(fetchFilteredDineInOrders());

  return { value: result.data };
});

export const updateDineInOrder = createAsyncThunk<
  void,
  {
    dineInOrderId: string;
    items: DineInBasketItemDto[];
    coupons: Coupon[];
  }
>('[DINEIN]/updateDineInOrder', async ({ dineInOrderId, items, coupons }, { dispatch }) => {
  const result = await posApi.post(posApiUrls.DINEIN_ORDERS_UPDATE(dineInOrderId), {
    items,
    coupons,
  });

  if (!result || result.status !== 200) {
    throw Error('Error updating tab');
  }

  if (isValidAxiosResponse(result)) {
    dispatch(doNotGroupItems(items.map((itm) => itm.id)));
    dispatch(clearBasket());
    dispatch(restartOrder);
  }
});

export const cancelDineInItem = createAsyncThunk<
  void,
  { tabId: string; dineInItemId: number },
  { state: RootState }
>('[DINEIN]/cancelDineInItem', async ({ dineInItemId, tabId }, { dispatch }) => {
  await posApi.put(posApiUrls.DINEIN_ORDERS_ITEM_CANCEL(tabId, dineInItemId));
  dispatch(ignoreItemsInPriceCalculations([dineInItemId]));
  await dispatch(getDineInOrder(tabId));
});

export const swapDineInTables = createAsyncThunk<
  void,
  { originTabId: string; targetTabId: string | undefined; targetTableName: string | undefined },
  { state: RootState }
>('[DINEIN]/swapDineInTables', async ({ originTabId, targetTabId, targetTableName }, { dispatch }) => {
  let swapTabId = targetTabId;

  if (targetTableName) {
    const newTabResult = await dispatch(postNewTab({ tableIdentifier: targetTableName }));
    const newTabData = newTabResult.payload as DineInOrderCreationResult;
    swapTabId = newTabData.orderId;
  }

  if (!swapTabId) return;

  const swapResult = await posApi.put(posApiUrls.DINEIN_ORDERS_SWAP_TABLES(originTabId, swapTabId));

  if (!swapResult || swapResult.status !== 200) {
    throw Error('Error swapping tables');
  }

  dispatch(fetchAllDineInOpenOrders());
  dispatch(getDineInOrder(originTabId));
});

export const cancelDineInOrder = createAsyncThunk<void, { tabId: string; reason: string }, { state: RootState }>(
  '[DINEIN]/cancelDineInOrder',
  async ({ reason, tabId }, { dispatch, getState }) => {
    const { dineIn, stores } = getState();
    const result = await posApi.put(posApiUrls.DINEIN_ORDERS_CANCEL(tabId), { reason });

    if (!result || result.status !== 200) {
      throw Error('Error canceling tab');
    }

    dispatch(clearBasket());

    if (dineIn.selectedDineInOrder?.id === tabId) {
      dispatch(clearSelectedDineInOrder());
      dispatch(restartIntakeState());
    }
    if (stores.selectedStore) {
      dispatch(fetchFilteredDineInOrders());
    }
  },
);

export const removeDineInCoupon = createAsyncThunk<
  void,
  { tabId: string; couponId: string },
  { state: RootState }
>('[DINEIN]/removeDineInCoupon', async ({ couponId, tabId }, { dispatch, getState }) => {
  const { dineIn } = getState();
  const isCouponSubmitted = dineIn.selectedDineInOrder?.coupons.find((c) => c.couponId === couponId) !== undefined;
  if (isCouponSubmitted) {
    await posApi.put(posApiUrls.DINE_IN_ORDERS_COUPON_REMOVE(tabId, couponId));
  }
  dispatch(removeCouponFromTheBasket(couponId));
  await dispatch(getDineInOrder(tabId));
});

export const getDineInOrder = createAsyncThunk<DineInOrder, string, { state: RootState }>(
  '[DINEIN]/getDineInOrder',
  async (id: string, { getState, dispatch }) => {
    const getDineInOrderResponse = await posApi.get<DineInOrder>(posApiUrls.DINEIN_ORDERS_TAB(id));
    const dineInOrder = { ...getDineInOrderResponse?.data };
    const { basketData: basketDataBeforeRecalculation } = getState().basket;
    const { products, options } = getState().products;
    const { toppings } = getState().toppings;
    const { couponsMasterData } = getState().coupons;

    if (dineInOrder) {
      const productsIdsInDineInOrder = dineInOrder.items.map((itm) => itm.productSelection.productId);
      const productsInDineInOrder = products.filter((p) => productsIdsInDineInOrder.includes(p.id));
      productsInDineInOrder.forEach((p) => {
        if (p.toppingSelectionSteps && p.toppingSelectionSteps.length > 0) {
          const matchingDineInItems = dineInOrder.items.filter((itm) => itm.productSelection.productId === p.id);
          for (let index = 0; index < matchingDineInItems.length; index += 1) {
            const matchingItem = matchingDineInItems[index];
            const productCustomization = createInitialCustomization(
              {
                originalOptionId: matchingItem.optionId,
                quantity: matchingItem.quantity,
                baseProduct: p,
                basketItemGuid: 'fake-guid',
                basketItemIndex: 1,
                sliceCustomizations: matchingItem.sliceCustomizations,
              } as CustomizationProduct,
              toppings,
              matchingItem.quantity,
              matchingItem.optionId,
            );
            matchingItem.selectedSetSteps = productCustomization.selections;
          }
        }
      });
    }

    if (dineInOrder?.items) {
      dineInOrder.items = dineInOrder.items.map((itm) => {
        const product = products.find((prd) => prd.id === itm.productSelection.productId);
        const option = options.find((opt) => opt.id === itm.productSelection.productOptionId);

        return {
          ...itm,
          itemName: product?.name,
          optionName: option?.name,
          itemId: itm.productSelection.productId,
          optionId: itm.productSelection.productOptionId,
        } as DineInItem;
      });
    }
    await dispatch(
      recalculateBasket({
        requestedBasket: generateBasketItemsFromDineInOrder(dineInOrder, products, toppings),
        coupons: generateFilledCouponsFromDineInOrder(dineInOrder, couponsMasterData),
        dineInOrder,
      }),
    );
    const { basketData: basketDataAfterRecalculation } = getState().basket;

    if (dineInOrder.items && basketDataBeforeRecalculation === basketDataAfterRecalculation) {
      throw new Error(`Could not recalculate basket for table with id ${dineInOrder.id}`);
    }

    return dineInOrder;
  },
);

export const submitPendingIteration = createAsyncThunk<void, void, { state: RootState }>(
  '[DINEIN]/submitPendingIteration',
  async (_, { dispatch, getState }) => {
    const { selectedDineInOrder } = getState().dineIn;
    const { basketItems, basketCoupons } = getState().basket;

    if (!basketItems?.length || !selectedDineInOrder) {
      return;
    }

    const dineInItemsIds = selectedDineInOrder.items.map((itm) => itm.id);
    const pendingBasketItems = basketItems.filter((bskItm) => !dineInItemsIds.includes(bskItm.id));
    const requestedBasketItems = pendingBasketItems.map((bskItm) => {
      return {
        id: bskItm.id,
        productSelection: {
          productId: bskItm.itemId,
          productOptionId: bskItm.optionId,
        },
        quantity: bskItm.quantity,
        sliceCustomizations: bskItm.sliceCustomizations,
        remark: bskItm.remark,
      };
    });

    const currentDineInCouponsIds = selectedDineInOrder.coupons.map((c) => c.couponId);
    const pendingDineInCoupons = basketCoupons.filter((bc) => !currentDineInCouponsIds.includes(bc.couponId));

    let dineInOrderId = selectedDineInOrder.id;

    if (dineInOrderId === '') {
      const result = await posApi.post<DineInOrderCreationResult>(
        `${posApiUrls.DINEIN_ORDERS}?tableIdentifier=${selectedDineInOrder.tableIdentifier}`,
      );

      if (!result || (result.status !== 200 && result.status !== 201)) {
        throw Error('Error opening tab');
      }

      dineInOrderId = result.data.orderId;
    }

    if (dineInOrderId !== '') {
      dispatch(updateDineInOrder({ dineInOrderId, items: requestedBasketItems, coupons: pendingDineInCoupons }));
    } else {
      throw new Error(`Failed creating open tab for table "${selectedDineInOrder.tableIdentifier}"`);
    }
  },
);
