import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { RootState } from 'stores';
import { FdmOrdersFiscalizationStatusView } from 'typings/Fiscalization/fiscalizationTypes';
import { EtaConfiguration, OrderingOption } from 'typings/Notifications';
import { updateNoOfOrdersToConfirmEta, updateEtaConfiguration, updateOrderingOption } from './notifications.slice';

const addClientToStoreGroup = createAsyncThunk<void, undefined, { state: RootState }>(
  '[NOTIFY]/addClientToStoreGroup',
  async (_arg, { getState }) => {
    const state = getState();
    const currentStoreId = state.stores.selectedStore?.id;

    if (state.notifications.notificationsConnectionId && currentStoreId) {
      await posApi.put(
        posApiUrls.NOTIFICATIONS_ADD_TO_STORE(state.notifications.notificationsConnectionId, currentStoreId),
      );
    }
  },
);

const getNoOfOrdersToConfirmEta = createAsyncThunk<void, undefined, { state: RootState }>(
  '[NOTIFY]/getNoOfOrdersToConfirmEta',
  async (_arg, { dispatch }) => {
    const result = await posApi.get(`${posApiUrls.OSM_HISTORY_ORDERS_TO_CONFIRM}`);
    dispatch(updateNoOfOrdersToConfirmEta(result.data));
  },
);

const getEtaConfiguration = createAsyncThunk<void, undefined, { state: RootState }>(
  '[NOTIFY]/getEtaConfiguration',
  async (_arg, { getState, dispatch }) => {
    const state = getState();
    const currentStoreId = state.stores.selectedStore?.id;

    if (currentStoreId) {
      const result = await posApi.get(`${posApiUrls.ETA_CONFIGURATION(currentStoreId)}`);

      const etaConfiguration: EtaConfiguration = {
        pickUpEtaMinutes: result.data.pickUpEtaMinutes,
        deliveryEtaMinutes: result.data.deliveryEtaMinutes,
      };
      dispatch(updateEtaConfiguration(etaConfiguration));

      const orderingOption: OrderingOption = {
        pickupOrdersAllowed: result.data.orderingState?.pickupOrdersAllowed,
        deliveryOrdersAllowed: result.data.orderingState?.deliveryOrdersAllowed,
      };
      dispatch(updateOrderingOption(orderingOption));
    }
  },
);

export const getOrdersFiscalStatus = createAsyncThunk('[NOTIFY]/getOrdersFiscalStatus', async () => {
  const result = await posApi.get<FdmOrdersFiscalizationStatusView>(posApiUrls.OSM_ORDER_FISCALIZATION_STATUS);

  return result.data;
});

// eslint-disable-next-line import/prefer-default-export
export { addClientToStoreGroup, getNoOfOrdersToConfirmEta, getEtaConfiguration };
