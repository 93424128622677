import { RootState } from 'stores';
import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import {
  CreateMachineResponse,
  IdentityServerSettings,
  MachineProfile,
  MachineToCreate,
  OnsiteResponse,
  RegisterOnSiteMachineData,
  TokenResult,
  UserLoginUX,
} from 'typings/Auth';
import {
  authenticationCallback,
  logOut,
  openLoginPopup,
  prepareCodeChallenge,
  saveToken,
  verifyUser,
} from 'utils/auth';
import { AxiosError, AxiosResponse } from 'axios';
import { isValidAxiosResponse } from 'typings/type-guards';
import { StoreConfiguration } from 'typings/Store';
import { getOnSiteSettings } from 'stores/Intake';

const createAuthUrl = (configuration: IdentityServerSettings): string | undefined => {
  prepareCodeChallenge();

  if (configuration !== undefined) {
    const queryParameters = [
      `/connect/authorize?`,
      `scope=${configuration.scope}`,
      `client_id=${configuration.clientId}`,
      `response_type=${configuration.responseType}`,
      `redirect_uri=${encodeURIComponent(`${window.location.origin}/offline-pos/#/login-redirect`)}`,
      `code_challenge=${localStorage.getItem('code_challenge')}`,
      `code_challenge_method=${configuration.codeChallengeMethod}`,
      `userTypes=employee,franchisee,administrator`,
    ].join('&');
    return configuration.url.concat(queryParameters);
  }
  // this means that getting config failed
  return undefined;
};

const isCustomerDisplayRoute = (): boolean => {
  return window.location.hash !== undefined && window.location.hash.includes('customer-display');
};

export const getTokenFromAuthenticationCode = createAsyncThunk<
  AxiosResponse<TokenResult>,
  string,
  { state: RootState; rejectValue: unknown }
>('[AUTH]/getTokenFromAuthenticationCode', async (code, { getState, rejectWithValue }) => {
  const state = getState();
  const { identityServer } = state.config;

  const qParams = [
    `grant_type=${identityServer?.grantType}`,
    `code=${code}`,
    `client_id=${identityServer?.clientId}`,
    `redirect_uri=${encodeURIComponent(`${window.location.origin}/offline-pos/#/login-redirect`)}`,
    `code_verifier=${localStorage.getItem('code_verifier')}`,
  ].join('&');

  const response = await posApi.post<TokenResult>(`${identityServer?.url}/connect/token`, qParams, {
    withCredentials: false,
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest',
    },
  });

  if (isValidAxiosResponse(response)) {
    return response;
  }
  return rejectWithValue(response);
});

export const logIn = createAsyncThunk<void, undefined, { state: RootState }>(
  '[AUTH]/logIn',
  async (_arg, { getState }) => {
    const state = getState();
    const { identityServer } = state.config;

    if (!identityServer) {
      console.error('Identity config not found');
      return;
    }

    const authStartUrl = createAuthUrl(identityServer);
    const loginUX = UserLoginUX.REDIRECT;
    const { origin } = window.location;
    let loginPageUrl = `${window.location.origin}/offline-pos/#/login-failed`;

    if (authStartUrl !== undefined) {
      try {
        const data = await fetch(authStartUrl);
        loginPageUrl = data.url;
      } catch (ex) {
        console.error('Could not fetch data from identity server!');
      }
    }

    if (loginUX === UserLoginUX.REDIRECT) {
      window.location.replace(loginPageUrl);
    } else {
      openLoginPopup(loginPageUrl);
    }

    authenticationCallback()
      .then((token) => {
        saveToken(token);
        window.location.href = `${origin}/offline-pos`;
      })
      .catch((err) => {
        console.warn('Error on token callback function:', err);
        window.location.href = `${origin}/offline-pos`;
      });
  },
);

export const getOnsiteMachine = createAsyncThunk<OnsiteResponse, void, { state: RootState }>(
  '[AUTH]/getOnSiteView',
  async (_, { dispatch, rejectWithValue, getState }) => {
    const { postPageRedirectStatus } = getState().authorization;
    const { instanceType } = getState().config;

    // eslint-disable-next-line no-restricted-globals
    if (location.href.match(/login-redirect/) && postPageRedirectStatus === 'PENDING') {
      return rejectWithValue('authentication cookie not loaded yet');
    }

    const initialSetupRequired = await posApi.get<boolean>(posApiUrls.INITIAL_SETUP_SHOW_FIRST_TIME_LAUNCH);

    if (initialSetupRequired.data === true) {
      return rejectWithValue(undefined);
    }

    await dispatch(getOnSiteSettings());

    const result = await posApi
      .get<OnsiteResponse>(posApiUrls.INITIAL_SETUP_MACHINE_DATA)
      .catch((err) => err as AxiosError);
    const isResultValid = isValidAxiosResponse<OnsiteResponse>(result);

    if (isResultValid) {
      const { storeId, touchpointId, isExpired, LastActivity } = result.data;
      return { storeId, touchpointId, isExpired, LastActivity };
    }

    if (instanceType === 'Central') {
      if (!verifyUser()) {
        if (!isCustomerDisplayRoute()) {
          dispatch(logIn());
        }

        return rejectWithValue(undefined);
      }
      dispatch(getInitialStores());
    }

    return rejectWithValue(result);
  },
);

export const getInitialStores = createAsyncThunk('[AUTH]/getInitialStores', async () => {
  return posApi.get<StoreConfiguration[]>(posApiUrls.INITIAL_SETUP_STORES, {
    headers: { Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
});
export const getMachineProfiles = createAsyncThunk('[AUTH]/getMachineProfiles', async (storeId: number) => {
  const profiles = await posApi.get<MachineProfile[]>(posApiUrls.MACHINE_PROFILES, {
    params: {
      storeId,
      includeExpired: false,
    },
    headers: { Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (!profiles || profiles.status !== 200) throw Error('Cannot get machine profiles');

  return profiles.data.map((profile) => {
    return {
      ...profile,
      machineName: profile.machineName ?? `${profile.storeOnSiteMachineType} (${profile.touchpointId})`,
    } as MachineProfile;
  });
});

export const registerStoreWithData = createAsyncThunk(
  '[AUTH]/registerStoreWithData',
  async (registerOnSiteMachineData: RegisterOnSiteMachineData) => {
    const result = await posApi.post(posApiUrls.INITIAL_SETUP_MACHINE_SESSION, registerOnSiteMachineData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('user_token')}`,
      },
    });
    if (isValidAxiosResponse(result)) {
      logOut();
    }
    return result;
  },
);

export const createMachineProfile = createAsyncThunk(
  '[AUTH]/createMachineProfile',
  async (machineToCreate: MachineToCreate, { dispatch }) => {
    const result = await posApi.post<CreateMachineResponse>(posApiUrls.MACHINE_PROFILES, machineToCreate, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('user_token')}`,
      },
    });
    if (!isValidAxiosResponse(result)) {
      throw new Error(
        (result as AxiosError<CreateMachineResponse>).response?.data?.errorCode ??
          'Error creating machine profile',
      );
    }
    await dispatch(getMachineProfiles(machineToCreate.storeId));
    return machineToCreate;
  },
);

export const hardLogout = createAsyncThunk('[AUTH]/hardLogin', async (_) => {
  logOut();
  return posApi.post(posApiUrls.ACCOUNT_MACHINE_LOGOUT).then(() => {
    window.location.href = `${window.location.origin}/offline-pos`;
  });
});

export const updateMachineLastActivity = createAsyncThunk('[AUTH]/updateMachineLastActivity', async () => {
  return posApi.post(posApiUrls.ACCOUNT_MACHINE_UPDATE_LAST_ACTIVITY);
});
