import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ spacing, radius, sizing, transitions, palette }) => {
  return {
    optionButton: {
      border: 'none',
      marginTop: spacing(0.5),
      textTransform: 'capitalize',
    },
    buttonBox: {
      position: 'relative',
      padding: spacing(0.5),
      borderRadius: radius.sm,
      width: sizing.full,
      display: 'flex',
      flexDirection: 'column',
      '&:hover': {
        backgroundColor: palette.customGray.light,
        '& > span': {
          color: palette.black.main,
          fontWeight: 600,
        },
        '& $buttonBoxSubCategory': {
          backgroundColor: palette.primary.main,
          color: palette.primary.contrastText,
        },
      },
    },
    activeButtonBox: {
      color: palette.black.main,
      backgroundColor: palette.customGray.light,
      fontWeight: 600,
    },
    buttonBoxSubCategory: {
      border: 'none',
      borderRadius: radius.round,
      color: palette.customGray.dark,
      width: sizing.buttonBox.md,
      height: sizing.buttonBox.md,
      minHeight: sizing.buttonBox.md,
      backgroundColor: palette.customGray.main,
      minWidth: sizing.buttonBox.md,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: transitions.create(['background-color', 'transform'], {
        duration: transitions.duration.standard,
      }),
      // ...typography.button,
    },
    activeButtonBoxSubCategory: {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
    },
    image: {
      width: sizing.buttonBox.md,
      height: sizing.buttonBox.md,
      minHeight: sizing.buttonBox.md,
      minWidth: sizing.buttonBox.md,
    },
    activeIcon: {
      position: 'absolute',
      top: 4,
      right: 4,
    },
  };
});

export default buildClasses;
