import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthorizationState, OnsiteResponse } from 'typings/Auth';
import { processToken } from 'utils/auth';
import {
  createMachineProfile,
  getInitialStores,
  getMachineProfiles,
  getOnsiteMachine,
  getTokenFromAuthenticationCode,
  hardLogout,
} from './auth.thunk-actions';

const initialState: AuthorizationState = {
  processing: true,
  postPageRedirectStatus: 'PENDING',
  initialStores: [],
  machineProfiles: [],
  storesLoading: false,
  onsiteMachineLoading: true,
  isAuthorized: false,
};

const authSlice = createSlice({
  name: '[AUTH]',
  initialState,
  reducers: {
    updateOnsiteStoreId(state, action: PayloadAction<number>) {
      return { ...state, onsiteMachine: { ...(state.onsiteMachine as OnsiteResponse), storeId: action.payload } };
    },
    setAuthorized(state, action: PayloadAction<boolean>) {
      return { ...state, isAuthorized: action.payload };
    },
    clearCreatingMachine(state) {
      return { ...state, createdMachine: undefined, creatingMachineStatus: undefined };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTokenFromAuthenticationCode.fulfilled, (state, action) => {
      const changeStatus = processToken(action.payload.data.access_token);
      if (changeStatus) {
        return { ...state, postPageRedirectStatus: 'COMPLETE' };
      }
      return state;
    });
    builder.addCase(getTokenFromAuthenticationCode.rejected, (state, action) => {
      console.warn(`There was an error getting token : ${action.error}`);
      return { ...state, postPageRedirectStatus: 'ERROR' };
    });
    builder.addCase(getOnsiteMachine.pending, (state, _action) => {
      return { ...state, onsiteMachineLoading: true };
    });
    builder.addCase(getOnsiteMachine.fulfilled, (state, action) => {
      return { ...state, onsiteMachine: action.payload, onsiteMachineLoading: false };
    });
    builder.addCase(getOnsiteMachine.rejected, (state, _action) => {
      return { ...state, onsiteMachineLoading: false };
    });
    builder.addCase(getInitialStores.pending, (state) => {
      return { ...state, storesLoading: true };
    });
    builder.addCase(getInitialStores.fulfilled, (state, action) => {
      return { ...state, initialStores: action.payload.data, storesLoading: false };
    });
    builder.addCase(getInitialStores.rejected, (state) => {
      return { ...state, storesLoading: false };
    });
    builder.addCase(getMachineProfiles.pending, (state) => {
      return { ...state, machineProfiles: [], machinesLoaded: false };
    });
    builder.addCase(getMachineProfiles.fulfilled, (state, action) => {
      return { ...state, machineProfiles: action.payload, machinesLoaded: true };
    });
    builder.addCase(getMachineProfiles.rejected, (state) => {
      return { ...state, machinesLoaded: true, machineProfiles: [] };
    });
    builder.addCase(createMachineProfile.pending, (state) => {
      return { ...state, creatingMachineStatus: 'PENDING' };
    });
    builder.addCase(createMachineProfile.fulfilled, (state, action) => {
      return { ...state, creatingMachineStatus: 'SUCCESS', createdMachine: action.payload };
    });
    builder.addCase(createMachineProfile.rejected, (state, action) => {
      return { ...state, creatingMachineStatus: 'ERROR', createMachineError: action.error.message };
    });
    builder.addCase(hardLogout.pending, (state) => ({ ...state, isAuthorized: false }));
  },
});

const { reducer } = authSlice;
export const { updateOnsiteStoreId, setAuthorized, clearCreatingMachine } = authSlice.actions;

export default reducer;
