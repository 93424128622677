import jwtDecode from 'jwt-decode';
import { DecodedToken } from 'typings/Auth/index';
import { destroyCodeChallenge } from './codeChallenge';

// Return type indicates if callback should be run
export const processToken = (token: string): boolean => {
  if (!window.opener) {
    token && saveToken(token);
    return true;
  }
  if (!token) {
    window.opener.postMessage(
      {
        type: 'ERROR_GETTING_TOKEN',
        message: 'No Access Token Found.',
      },
      window.location.origin,
    );
    window.close();
    return false;
  }

  window.opener.postMessage(
    {
      type: 'ACCESS_TOKEN',
      accessToken: token,
    },
    window.location.origin,
  );
  window.close();
  return false;
};

export const clearToken = (): void => {
  localStorage.removeItem('user_token');
  localStorage.removeItem('user_id');
};

export const saveToken = (token: string): void => {
  localStorage.setItem('user_token', token);
  const decodedToken = jwtDecode(token) as DecodedToken;

  localStorage.setItem('user_id', decodedToken.sub);
};

export const logOut = (): void => {
  destroyCodeChallenge();
  clearToken();
};
