import { PaymentConfiguration } from 'stores/Config/configTypes';
import { getRegexLiterals } from 'utils/payment/PaymentUtils';

function positiveIntegerValidator(): RegExp {
  // eslint-disable-next-line prefer-regex-literals
  return /^\d*[1-9]\d*$/;
}

function moneyValidator(paymentSettings: PaymentConfiguration): RegExp {
  const decimalSeparatorChunk = getRegexLiterals(paymentSettings.decimalSeparator);
  const thousandsSeparatorChunk = getRegexLiterals(paymentSettings.thousandsSeparator);

  const reg = new RegExp(
    `^([0-9]+${thousandsSeparatorChunk}?)?[0-9]+(${decimalSeparatorChunk}[0-9]{1,${paymentSettings.decimalPlacesForEdition}})?$`,
  );

  return reg;
}
function isBelowValue(testVal: number, limit: number): boolean {
  return testVal <= limit;
}
function isAboveValue(testVal: number, limit: number): boolean {
  return testVal >= limit;
}
function isNoLongerThan(testVal: string, maxLength: number): boolean {
  return testVal.length <= maxLength;
}
function isNotEmpty(testVal: string): boolean {
  return testVal !== undefined && testVal.trim() !== '';
}

export function filePath(): RegExp {
  return /^[a-z][:]\\[A-Z+a-z ()0-9\\]+[.]exe+$/i;
}
export const noBlankAtBeginningNoDigitsPattern = /([a-zA-Z])/;
export const digitsOnlyPattern = /^\d+$/;
export const emailPattern =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
export const phoneNumberPattern =
  /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/;

const Validators = {
  Money: {
    Validator: moneyValidator,
    Message: 'Not a proper payment value',
  },
  IsBelowOrderTotal: {
    Validator: isBelowValue,
    Message: 'Value must be lower than order total',
  },
  IsBelowGivenValue: {
    Validator: isBelowValue,
  },
  IsAboveGivenValue: {
    Validator: isAboveValue,
  },
  IsNoLongerThan: {
    Validator: isNoLongerThan,
  },
  IsNotEmpty: {
    Validator: isNotEmpty,
  },
  IsPositiveInteger: {
    Validator: positiveIntegerValidator,
    Message: 'Value must be positive number',
  },
};

export default Validators;
