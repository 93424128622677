export interface CashierState {
  operationInProgress?: CashierOperation;
  checkingCashierInProgress: boolean;
  loggedCashier?: Cashier;
  timekeepingOperationUser?: Cashier;
  loginState: LoginStates;
  clockedInEmployees: EmployeeInfo[];
  errorCode?: string;
  revalidationResult?: RevalidationResult;
  employeeCashBalanceInfo?: EmployeeCashBalance;
}

export type CashierOperation = 'unlock' | 'clockIn' | 'clockOut' | 'revalidate';

export enum LoginStates {
  enterPin = 'enterPin',
  cashierLoginSuccess = 'cashierLoginSuccess',
  clockInSuccess = 'clockInSuccess',
  clockOutPendingConfirmation = 'clockOutPendingConfirmation',
  clockOutSuccess = 'clockOutSuccess',
  clockedInEmployees = 'clockedInEmployees',
  error = 'error',
  timekeepingError = 'timekeepingError',
  cashflowConfirmationError = 'cashflowConfirmationError',
  dataSync = 'dataSync',
  cashFlow = 'cashFlow',
  cashFlowPinPad = 'cashFlowPinPad',
}
export interface EmployeeInfo {
  userId?: number;
  fullname: string;
  role: string;
  clockedInAt: string;
  breakDurationInMinutes?: number;
}

export interface AuthorizationDto {
  userId: number;
  familyName: string;
  givenName: string;
  redirectUrl: string | undefined;
}

export interface ClockOutData {
  secret: string;
  employeeId?: number;
}

export interface CashierDetailsDto {
  userId: number;
  familyName: string;
  givenName: string;
  mainRole?: string;
  permissions: CashierPermission[];
}

export interface EmployeeCashBalance {
  requiresClockOutConfirmation: boolean;
  cashInPocket: number;
  ordersInCashTotal: number;
  ordersInPinTotal: number;
  employeeId: number;
  employeeName: string;
  roleDisplayName?: string;
  timeOfCheck: string; // not used atm
  shiftTime: string;
  breakTimeMinutes: number; // not on backend yet
}

export interface Cashier {
  id: number;
  firstName: string;
  lastName: string;
  roleName?: string;
  permissions: CashierPermission[];
}

export interface Credentials {
  Secret: string;
  RedirectUrl: string | undefined;
}

export interface TimekeepingResult {
  isSuccess: boolean;
  cashier?: Cashier;
  errorCode?: string;
}

export interface LoginContext {
  pin: string;
}

export interface LoginResult {
  success: boolean;
}

export enum LocalStorageAuthConsts {
  AuthQuery = 'auth-query',
  RedirectUrlParam = 'redirect-url',
}
export interface RevalidationResult {
  success: boolean;
  revalidateFor: string;
}

export enum CashierPermission {
  OrderIntake = 'OrderIntake',
  TakeOrders = 'TakeOrders',
  ChangeTotalPrice = 'ChangeTotalPrice',
  AddCoupon = 'AddCoupon',
  ChooseStore = 'ChooseStore',
  SeeCurrentStoreOrders = 'SeeCurrentStoreOrders',
  SeeAllAttachedStoreOrders = 'SeeAllAttachedStoreOrders',
  FinishOpenAndPausedOrder = 'FinishOpenAndPausedOrders',
  CancelOrder = 'CancelOrder',
  AllowOrderEdit = 'AllowOrderEdit',
  ChangeOrder = 'ChangeOrder',
  Orders = 'Orders',
  ManageOpenOrders = 'ManageOpenOrders',
  ManagePosSettings = 'ManagePosSettings',
  DownloadOrder = 'DownloadOrder',
  EmailOrder = 'EmailOrder',
  RepeatOrder = 'RepeatOrder',
  PrintOrder = 'PrintOrder',
  ChangeOrderStatus = 'ChangeOrderStatus',
  EditEta = 'EditEta',
  AllowAdyenRefund = 'AllowAdyenRefund',
}
