/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import i18n from 'i18n';

const CMS_BASE_URL = 'http://localhost:30000';

const cmsClient = axios.create({
  baseURL: CMS_BASE_URL,
  params: {
    locale: i18n.language,
  },
  timeout: 5000,
  headers: {
    'Content-type': 'application/json',
  },
});

cmsClient.interceptors.request.use((request) => {
  if (BASE_CMS_CONTROLLERS.some((lc) => request?.url?.includes(lc))) {
    return request;
  }
  return { ...request, params: { ...request.params, locale: i18n.language } };
});

export function updateCmsClient(langCode: string) {
  if (!cmsClient.defaults.params) cmsClient.defaults.params = {};
  cmsClient.defaults.params.locale = langCode;
}
export function initBaseUrl() {
  // eslint-disable-next-line no-constant-condition
  if (process.env.NODE_ENV === 'production' || true) {
    const location = window.location.href;
    const apiBaseLength = location.indexOf(process.env.PUBLIC_URL);
    cmsClient.defaults.baseURL = `${location.substring(0, apiBaseLength)}/`;
  }
}

export const fetchTranslations = (): Promise<AxiosResponse<i18nResource[]>> =>
  cmsClient.get(`${CMS_CONTROLLERS.LABELS}`, { params: { locale: 'all' } });
export const fetchSupportedLanguages = (): Promise<AxiosResponse<CmsLanguage[]>> =>
  cmsClient.get(`${CMS_CONTROLLERS.LOCALES}`, { params: { locale: null } });
export const fetchPOSImages = (): Promise<AxiosResponse<CmsPosImages>> =>
  cmsClient.get(`${CMS_CONTROLLERS.IMAGES}`);
export const fetchCustomerDisplayMedia = (): Promise<AxiosResponse<CmsCustomerDisplay>> =>
  cmsClient.get(`${CMS_CONTROLLERS.CUSTOMER_DISPLAY}`);

// eslint-disable-next-line @typescript-eslint/naming-convention
interface i18nResource {
  namespace: string;
  resource: object;
  locale: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention

export interface CmsMedia {
  name: string;
  url: string;
  ext: string;
}

export interface CmsLanguage {
  name: string;
  code: string;
  isDefault: boolean;
  image: {
    url: string;
  };
}

export interface CmsCustomerDisplay {
  id: number;
  basketImage: CmsCarouselBanner;
  splashScreen: CmsCarouselBanner;
  headerIcon: CmsMedia;
}

export interface CmsPosImages {
  id: number;
  logo: CmsMedia;
  splashScreen: CmsMedia;
  bestSeller: CmsMedia;
}

export interface CmsCarouselBanner {
  carouselTimeSeconds?: number;
  images: CmsMedia[];
}

export const CMS_CONTROLLERS = {
  LABELS: '/api/content/translations/pos',
  LOCALES: '/api/content/locales/pos',
  IMAGES: '/api/content/images/pos',
  CUSTOMER_DISPLAY: '/api/content/images/pos/customer-display',
};

export const BASE_CMS_CONTROLLERS = [CMS_CONTROLLERS.LABELS, CMS_CONTROLLERS.LOCALES];
