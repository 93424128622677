import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { RootState } from 'stores';
import { getClockedInUsers, getLoggedInCashier } from 'stores/Cashier';
import { resetCouponsState } from 'stores/Coupons';
import { restartDineInState } from 'stores/DineIn/dineIn.slice';
import { resetFloorsState } from 'stores/Floors/floors.slice';
import { restartIntakeState } from 'stores/Intake';
import { restartLocalOrdersState } from 'stores/LocalOrders';
import { getNoOfOrdersToConfirmEta } from 'stores/Notifications/notifications.thunk-actions';
import { updateEtaConfiguration } from 'stores/Notifications/notifications.slice';
import { resetProductsState } from 'stores/Products';
import { EtaConfiguration } from 'typings/Notifications';
import { SwitchStore, StoreInfo } from 'typings/Store';

export const getSelectedStore = createAsyncThunk('[STORE]/getSelectedStore', async () => {
  const result = await posApi.get<StoreInfo>(posApiUrls.SELECTED_STORE_GET);

  return result.data;
});

export const getSwitchStores = createAsyncThunk('[STORE]/getSwitchStores', async () => {
  const result = await posApi.get<SwitchStore[]>(posApiUrls.SWITCH_STORES);

  return result.data;
});

export const switchStore = createAsyncThunk<StoreInfo, { storeId: string }, { state: RootState }>(
  '[STORE]/switchStore',
  async ({ storeId }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(restartIntakeState());
      dispatch(restartDineInState());
      dispatch(resetProductsState());
      dispatch(resetFloorsState());
      dispatch(resetCouponsState());
      dispatch(restartLocalOrdersState());
      dispatch(getClockedInUsers());

      const result = await posApi.put<StoreInfo>(`${posApiUrls.SWITCH_STORES}/${storeId}`);

      if (result.status === 200) {
        dispatch(getNoOfOrdersToConfirmEta());
        return result.data as StoreInfo;
      }
      throw new Error('StoreSwitchError');
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateStoreEtaConfiguration = createAsyncThunk<
  null,
  { storeId: number; config: EtaConfiguration },
  { state: RootState }
>('[STORE]/updateStoreEtaConfiguration', async ({ storeId, config }, { dispatch }) => {
  const result = await posApi.put<EtaConfiguration>(posApiUrls.SYNCHRONIZATION_ETA(storeId), {
    PickUpEtaMinutes: config.pickUpEtaMinutes,
    DeliveryEtaMinutes: config.deliveryEtaMinutes,
  });

  if (result.status === 200) {
    dispatch(updateEtaConfiguration(config));
  }

  throw new Error('updateStoreEtaConfigurationError');
});
