const authenticationCallback = (): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const eventHandler = (event: MessageEvent) => {
      const hash = event.data;
      const token = hash.accessToken;
      if (hash.type === 'ACCESS_TOKEN') {
        resolve(token);
        window.removeEventListener('message', eventHandler);
      } else if (hash.type === 'ERROR_GETTING_TOKEN') {
        reject(hash.message);
        window.removeEventListener('message', eventHandler);
      }
    };

    window.addEventListener('message', eventHandler, false);
  });
};

export default authenticationCallback;
