import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing, sizing, radius }) => {
  return {
    gridItem: {
      backgroundColor: palette.white,
      border: `1px solid ${palette.customGray.main}`,
      color: palette.black.main,
      padding: `${spacing(1)} ${spacing(2)}`,
      borderRadius: radius.sm,
      position: 'relative',
      height: sizing.full,
      overflow: 'hidden',
    },
    active: {
      borderColor: palette.black.main,
      borderWidth: '2px',
      // hack to prevent card from miss-aligning after select
      padding: `calc(${spacing(1)} - 1px) calc(${spacing(2)} - 1px)`,
    },
  };
});
export default buildClasses;
