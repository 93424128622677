import { CouponValidity } from 'containers/Intake/IntakeConsts';
import { OSMOrderItem, OSMDiscount } from 'stores/AllOrders';
import {
  BasketItem,
  ItemPriceDetails,
  ReceiptProduct,
  ActiveDiscount,
  CouponInformation,
  ReceiptDiscount,
  BasketDataResponse,
  VirtualReceipt,
  BoundedBasketItem,
  AutomaticDiscountsInformation,
} from 'typings/Basket';
import { Coupon } from 'typings/Coupons';

function sortDiscountsByValidity(discountA: ReceiptDiscount, discountB: ReceiptDiscount): number {
  if (discountA.couponValidity === CouponValidity.Active) return -1;
  if (discountB.couponValidity === CouponValidity.Active) return 1;
  return 0;
}

function getProductsAffectedByDiscount(matchingBasketItems: number[], allBasketItems: BasketItem[]): BasketItem[] {
  const affectedItems: BasketItem[] = [];
  matchingBasketItems.forEach((basketItemId) => {
    const foundItem = allBasketItems.find((el) => el.receiptId === basketItemId);
    foundItem && affectedItems.push(foundItem);
  });

  return affectedItems;
}

function generateVirtualReceiptProducts(
  basketItems: BasketItem[],
  itemsPriceDetails: ItemPriceDetails[],
  boundedProducts: BoundedBasketItem[],
  automaticProducts: BasketItem[],
): ReceiptProduct[] {
  const newReceiptItems: ReceiptProduct[] = [];
  const autoAddedItemsIds = boundedProducts.filter((bp) => bp.quantity > 0).map((bp) => bp.id);
  const regularProductsPriceDetails = itemsPriceDetails.filter(
    (itm) => !autoAddedItemsIds.includes(itm.basketItemId),
  );
  regularProductsPriceDetails.forEach((recalculatedItem) => {
    const basketItem = basketItems.find((el) => el.receiptId === recalculatedItem.receiptItemId);
    const boundedItems = boundedProducts.filter((el) => el.boundedToReceiptId === recalculatedItem.receiptItemId);
    const boundedProductsWithPrices = boundedItems.map((boundedItem) => {
      const boundedItemPrice = itemsPriceDetails.find((el) => el.basketItemId === boundedItem?.id);
      return boundedItem && boundedItemPrice
        ? ({
            ...boundedItem,
            itemPrice: boundedItemPrice,
          } as ReceiptProduct)
        : undefined;
    });
    if (basketItem !== undefined) {
      newReceiptItems.push({
        ...basketItem,
        boundedProducts: boundedProductsWithPrices.filter((el) => !!el) as ReceiptProduct[],
        itemPrice: recalculatedItem,
      });
    }
  });

  automaticProducts
    .filter((ap) => ap.quantity > 0)
    .forEach((ap) => {
      const autoItemPrice = itemsPriceDetails.find((el) => el.basketItemId === ap.id);
      newReceiptItems.push({
        ...ap,
        isAutoItem: true,
        itemPrice: autoItemPrice as ItemPriceDetails,
      });
    });
  return newReceiptItems;
}

function getReceiptProductsIncludedInDiscount(
  matchingReceiptItemIds: number[],
  allReceiptProducts: ReceiptProduct[],
): ReceiptProduct[] {
  let includedProducts: ReceiptProduct[] = [];

  allReceiptProducts.forEach((el) => {
    const isIncludedInDiscount = matchingReceiptItemIds.some((id) => id === el.receiptId);
    const boundProductsIncludedInDiscount =
      el.boundedProducts?.filter((boundProductElement) =>
        matchingReceiptItemIds.some((id) => id === boundProductElement.receiptId),
      ) ?? [];

    if (isIncludedInDiscount) {
      includedProducts.push(el);
    }

    if (boundProductsIncludedInDiscount.length > 0) {
      includedProducts = includedProducts.concat(boundProductsIncludedInDiscount);
    }
  });

  return includedProducts;
}

function generateVirtualReceiptDiscounts(
  basketItems: BasketItem[],
  coupons: Coupon[],
  appliedDiscounts: ActiveDiscount[],
  couponsInformation: CouponInformation[],
  automaticDiscountsInformation: AutomaticDiscountsInformation[],
  allReceiptProducts: ReceiptProduct[],
): ReceiptDiscount[] {
  // automagically applied by backend
  const automaticDiscounts = appliedDiscounts.filter((ad) => !coupons.some((el) => el.couponId === ad.couponId));
  const automaticReceiptDiscounts: ReceiptDiscount[] = automaticDiscounts.map((ad) => {
    return {
      couponValidity: CouponValidity.Active,
      discountType: ad.type,
      description: ad.description,
      couponId: ad.couponId,
      canDelete: false, // all automatic coupons cannot be deleted
      basketItems: getProductsAffectedByDiscount(ad.matchingReceiptItemIds, basketItems),
      isSet: ad.isSetDiscount,
      value: ad?.value,
      discountItems: allReceiptProducts.filter((el) =>
        ad?.matchingReceiptItemIds.some((id) => id === el.receiptId),
      ),
    };
  });

  // automatic discounts for which we want to show some info why they are not activated
  const notActivatedAutomaticDiscounts: ReceiptDiscount[] = automaticDiscountsInformation.map((nad) => {
    return {
      couponValidity: nad.status,
      discountType: nad.discount.type,
      description: nad.discount.description,
      couponId: nad.discount.couponId,
      canDelete: false,
      isSet: nad.discount.isSetDiscount,
      value: nad.discount?.value,
    };
  });

  // added by user as coupon, recalculated and possibly applied by backend.
  const manualReceiptDiscounts: ReceiptDiscount[] = couponsInformation
    .map((md) => {
      const activeDiscount = appliedDiscounts.find((el) => el.couponId === md.couponId);
      const discountFromCoupon = coupons.find((el) => el.couponId === md.couponId);
      if (discountFromCoupon) {
        return {
          couponValidity: md.status,
          description: discountFromCoupon.description,
          discountType: activeDiscount?.type,
          canDelete: true,
          code: discountFromCoupon.couponCode,
          couponId: md.couponId,
          isSet: activeDiscount?.isSetDiscount ?? false,
          value: activeDiscount?.itemsValue,
          discountProducts: getReceiptProductsIncludedInDiscount(
            activeDiscount?.matchingReceiptItemIds ?? [],
            allReceiptProducts,
          ),
        } as ReceiptDiscount;
      }
      return null;
    })
    .filter((el) => el !== null) as ReceiptDiscount[];

  return [
    ...automaticReceiptDiscounts,
    ...manualReceiptDiscounts.sort(sortDiscountsByValidity),
    ...notActivatedAutomaticDiscounts,
  ];
}

export const generateVirtualReceipt = (
  allBasketItems: BasketItem[],
  basketCoupons: Coupon[],
  recalculatedBasket: BasketDataResponse,
  boundedProducts: BoundedBasketItem[],
  automaticItems: BasketItem[],
  customerCreditToUse?: number,
): VirtualReceipt => {
  const receiptProducts = generateVirtualReceiptProducts(
    allBasketItems,
    recalculatedBasket.basketCalculationResult.itemsDetails,
    boundedProducts,
    automaticItems,
  );

  const receiptDiscounts = generateVirtualReceiptDiscounts(
    receiptProducts,
    basketCoupons,
    recalculatedBasket.basketCalculationResult.activeDiscounts,
    recalculatedBasket.basketCalculationResult.couponsInformation,
    recalculatedBasket.basketCalculationResult.automaticDiscountsInformation,
    receiptProducts,
  );

  let receiptProductsWithoutSetItems = [...receiptProducts];

  receiptDiscounts.forEach((rd) => {
    if (rd.isSet && rd.discountProducts) {
      rd.discountProducts.forEach((bi) => {
        receiptProductsWithoutSetItems = receiptProductsWithoutSetItems.filter((el) => el.id !== bi.id);
      });
    }
  });

  const customerCreditDiscount = customerCreditToUse
    ? ({
        couponValidity: 'Active',
        description: 'Customer credit',
        isSet: false,
        canDelete: true,
        value: customerCreditToUse,
        discountType: 'CustomerCredit',
      } as ReceiptDiscount)
    : undefined;
  customerCreditDiscount && receiptDiscounts.push(customerCreditDiscount);

  return { receiptProducts: receiptProductsWithoutSetItems, receiptDiscounts };
};
export const generateVirtualReceiptForPaidOrder = (
  osmItems: OSMOrderItem[],
  basketItems: BasketItem[],
  osmDiscounts?: OSMDiscount[],
): VirtualReceipt => {
  const receiptProducts = osmItems.map((osmItem) => {
    const basketItem = basketItems.find((el) => el.id === osmItem.id);
    return {
      basketItemGuid: osmItem.id.toString(),
      id: osmItem.id,
      itemId: osmItem.productSelection.productId,
      itemName: basketItem?.itemName ?? osmItem.description,
      itemPrice: {
        basketItemId: osmItem.id,
        quantity: osmItem.quantity,
        receiptItemId: osmItem.id,
        total: osmItem.total,
        unitPrice: osmItem.unitPrice,
      },
      optionId: osmItem.productSelection.productOptionId ?? 0,
      optionName: basketItem?.optionName ?? osmItem.type,
      quantity: osmItem.quantity,
      receiptId: osmItem.id,
      remark: osmItem.remark,
      sliceCustomizations: basketItem?.sliceCustomizations,
    } as ReceiptProduct;
  });

  const receiptDiscounts = osmDiscounts?.map((osmDiscount) => {
    return {
      couponValidity: CouponValidity.Active,
      description: osmDiscount.description,
      isSet: osmDiscount.isSetDiscount,
      canDelete: false,
      code: osmDiscount.code,
      couponId: osmDiscount.couponId,
      discountType: osmDiscount.type,
      value: osmDiscount.value,
      discountProducts: receiptProducts.filter((el) =>
        osmDiscount?.matchingItems.some((matchingItem) => matchingItem.orderItemId === el.id),
      ),
    } as ReceiptDiscount;
  });

  let receiptProductsWithoutSetItems = [...receiptProducts];

  receiptDiscounts?.forEach((rd) => {
    if (rd.isSet && rd.discountProducts) {
      rd.discountProducts.forEach((bi) => {
        receiptProductsWithoutSetItems = receiptProductsWithoutSetItems.filter((el) => el.id !== bi.id);
      });
    }
  });
  return { receiptProducts: receiptProductsWithoutSetItems, receiptDiscounts: receiptDiscounts ?? [] };
};
