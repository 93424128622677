import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { RewardsResponse } from 'typings/Loyalty';

export const getAllRewards = createAsyncThunk<
  RewardsResponse,
  { pickupType: PickUpTypesValues; storeId: number; customerId: number }
>('[LOYALTY]/fetchAllRewardsData', async (requestParams) => {
  const result = await posApi.put<RewardsResponse>(posApiUrls.REWARDS, {
    basket: {
      pickupType: requestParams.pickupType,
    },
    storeId: requestParams.storeId,
    customerId: requestParams.customerId,
  });
  return result.data;
});
