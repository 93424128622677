import { CSSObject, Theme } from '@mui/material/styles';

import { BASE_THEME } from './base.theme.css';

const VER_COLORS = {
  ...BASE_THEME.palette,
  primary: {
    main: '#aba29c',
    light: '#bdb3ac',
    dark: '#89827d',
    contrastText: '#fff',
  },
  secondary: {
    ...BASE_THEME.palette.secondary,
    main: '#ededed',
  },
  error: {
    ...BASE_THEME.palette.error,
    main: '#D9534F',
    dark: '#6f0094',
    light: '#df80ff',
  },
  success: {
    light: '#bdb3ac',
    main: '#aba29c',
    dark: '#89827d',
    contrastText: '#fff',
  },
};

export const VERHAGE: Theme = {
  ...BASE_THEME,
  palette: {
    ...VER_COLORS,
    virtualReceipt: {
      items: {
        discount: {
          ...BASE_THEME.palette.virtualReceipt.items.discount,
          error: {
            icon: 'rgb(255,41,41)',
            background: VER_COLORS.error.light,
          },
        },
      },
    },
  },
  components: {
    ...BASE_THEME.components,
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: `#aba29c`,
        },
        arrow: {
          color: `#fff`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        colorSecondary: {
          color: 'white',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ...(BASE_THEME.components?.MuiCssBaseline?.styleOverrides as CSSObject),
        '::-webkit-scrollbar-thumb': {
          background: '#0d8640',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: '#0d8640',
        },
      },
    },
  },
};
