export interface GiftCardActivationDto {
  giftCards: GiftCardActivationAttempt[];
}
export interface GiftCardActivationAttempt {
  id: string; // ACTIVATION ID, not card number!
  amount: number;
  status: GiftCardActivationStatus;
}

export enum GiftCardActivationStatus {
  Initialized = 'Initialized',
  Started = 'Started',
  Succeeded = 'Succeeded',
  Failed = 'Failed',
}
