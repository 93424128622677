import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import { getSwitchedStore } from 'stores/Store/store.selectors';
import { TransferredIntake } from 'typings/HybridMode';

const prepareIntakeState = ({ basket, intake, stores }: RootState) => {
  if (!stores.selectedStore) return undefined;

  const storeId = stores.selectedStore.id;

  return {
    basketItems: basket.basketItems,
    basketCoupons: basket.basketCoupons,
    manualDeliveryCharge: basket.manualDeliveryCharge,
    manualPriceOverride: intake.manualPriceOverride,
    remarks: intake.remarks,
    activeDeliveryType: intake.activeDeliveryType,
    activePaymentMethod: intake.activePaymentMethod,
    storeId,
  } as TransferredIntake;
};

export const getIntakeStateToTransfer = () =>
  createSelector(getSwitchedStore, prepareIntakeState, (switchedStore, intakeState) => {
    const storeIsSwitched = switchedStore && !switchedStore.isDefaultStore;

    if (storeIsSwitched) return undefined;

    return intakeState;
  });
