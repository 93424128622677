import { Meal } from 'typings/Coupons';
import { isCustomizableProductComplete } from './productCustomizationUtils';

export const isCouponComplete = (meals: Meal[]) => {
  return meals.some((ml) => {
    if (!ml.selectedProduct || !ml.selectedProduct.baseProduct) {
      return false;
    }

    return isCustomizableProductComplete(ml.selectedProduct);
  });
};
