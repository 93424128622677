import { createAsyncThunk } from '@reduxjs/toolkit';
import { FloorEntity } from 'typings/Tables';
import posApi, { posApiUrls } from 'API/PosApi';
import { RootState } from 'stores';
import { setFloors, updateFloor } from './floors.slice';

export interface SaveFloorsAction {
  currentState: FloorEntity[];
  deletedFloorsIds: string[];
}

export const saveFloors = createAsyncThunk<FloorEntity[], SaveFloorsAction, { state: RootState }>(
  '[TABLES]/saveFloors',
  async (saveAction, { dispatch }) => {
    dispatch(setFloors(saveAction.currentState));
    const stringifiedFloors = JSON.stringify(saveAction.currentState);
    return posApi.post(posApiUrls.RESTAURANT_FLOOR_PLAN, {
      arrangement: stringifiedFloors,
      deletedFloorsIds: saveAction.deletedFloorsIds,
    });
  },
);

export const loadFloors = createAsyncThunk('[TABLES]/loadFloors', async () => {
  const response = await posApi.get<{ arrangement: string; storeId: number }>(posApiUrls.RESTAURANT_FLOOR_PLAN);
  return response.data.arrangement;
});

export const uploadFloorBackground = createAsyncThunk<void, FloorEntity, { state: RootState }>(
  '[TABLES]/uploadFloorBackground',
  async (floor, { dispatch }) => {
    if (!floor.background) {
      return;
    }

    const blob = await fetch(floor.background).then((f) => f.blob());
    const urlParts = floor.background.split('/');
    const fileName = urlParts[urlParts.length - 1];
    const formData = new FormData();
    formData.append('image', blob, fileName);
    const uploadResponse = await posApi.put<string>(
      posApiUrls.RESTAURANT_FLOOR_PLAN_BACKGROUND_UPLOAD(floor.id),
      formData,
    );

    const updatedFloor = {
      ...floor,
      background: uploadResponse.data,
      backgroundModificationTimestamp: new Date().getTime(),
    } as FloorEntity;
    dispatch(updateFloor(updatedFloor));
  },
);
