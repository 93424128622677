import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logOutCashier } from 'stores/Cashier';
import { StoreInfo, StoresState, SwitchStore } from 'typings/Store';
import { getSelectedStore, getSwitchStores, switchStore } from './store.thunk-actions';

const initialState: StoresState = {
  selectedStore: null,
  initialStores: [],
  initialStoresLoading: true,
};

const storeSlice = createSlice({
  name: '[STORE]',
  initialState,
  reducers: {
    setSelectedStore(state, action: PayloadAction<StoreInfo>) {
      return {
        ...state,
        selectedStore: action.payload,
      };
    },
    setAvailableStores(state, action: PayloadAction<SwitchStore[]>) {
      return {
        ...state,
        availableStores: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(switchStore.pending, (state) => {
      return {
        ...state,
        switchingStoreStatus: 'pending',
      };
    });
    builder.addCase(switchStore.fulfilled, (state, action) => {
      return {
        ...state,
        switchingStoreStatus: 'success',
        selectedStore: action.payload,
      };
    });
    builder.addCase(switchStore.rejected, (state) => {
      return {
        ...state,
        switchingStoreStatus: 'failure',
      };
    });
    builder.addCase(getSwitchStores.fulfilled, (state, action) => {
      return {
        ...state,
        availableStores: action.payload ?? [],
      };
    });
    builder.addCase(getSwitchStores.rejected, (state, action) => {
      return {
        ...state,
        availableStores: [],
      };
    });
    builder.addCase(getSelectedStore.fulfilled, (state, action) => ({
      ...state,
      selectedStore: action.payload,
    }));
    builder.addCase(logOutCashier.fulfilled, () => {
      return initialState;
    });
  },
});

const { reducer } = storeSlice;
export const { setSelectedStore, setAvailableStores } = storeSlice.actions;

export default reducer;
