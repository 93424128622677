import { processToken } from './tokenHelpers';

const openLoginPopup = (url: string) => {
  const popupWidth = window.screen.width / 3;
  const popupHeigh = window.screen.height / 2;
  const left = window.screen.width / 2 - popupWidth / 2;
  const top = window.screen.height / 2 - popupHeigh / 2;
  const win = window.open(
    url,
    'Login to S4D Identity',
    `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=${popupWidth}, height=${popupHeigh}, top=${top}, left=${left}`,
  );
  if (win) win.opener = window;

  // below code part is workaround as there is no 'close' event that can be listened on login popup window.
  // this function exists to inform offline-pos that user closed popup login without logging in.
  const checkForClosedPopup = setInterval(() => {
    if (win?.closed) {
      clearInterval(checkForClosedPopup);
      processToken('');
    }
  }, 1000);
};

export default openLoginPopup;
