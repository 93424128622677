import { Theme } from '@mui/material/styles';
import brandLogo from 'assets/logos/BELCHICKEN/logo.svg';

import { BASE_THEME } from './base.theme.css';

const BELCHICKEN_COLORS = {
  primary: {
    main: '#F7921F',
    light: '#F7921F',
    dark: '#462305',
    contrastText: '#fff',
  },
  secondary: {
    ...BASE_THEME.palette.secondary,
  },
  error: {
    ...BASE_THEME.palette.error,
    main: '#FF003D',
  },
  success: {
    light: '#81c784',
    main: '#4caf50',
    dark: '#388e3c',
    contrastText: '#fff',
  },
};

export const BELCHICKEN = {
  ...BASE_THEME,
  brandLogo,
  loginImage: {
    url: brandLogo,
    backgroundSize: 'contain',
  },
  palette: {
    ...BASE_THEME.palette,
    ...BELCHICKEN_COLORS,

    grey: {
      ...BASE_THEME.palette.grey,
      '100': '#f5f5f5',
      '200': '#EAEAEA',
      '500': '#A9A9A9',
      '600': '#8E8E8E',
    },
    green: {
      ...BASE_THEME.palette.green,
      '100': '#F1FFEC',
    },
    appBar: {
      main: '#462305',
      subMain: '#71441E',
      subMainText: '#fff',
    },
  },
  virtualReceipt: {
    items: {
      discount: {
        ...BASE_THEME.palette.virtualReceipt.items.discount,
        error: {
          icon: 'rgb(255,41,41)',
          background: BELCHICKEN_COLORS.error.light,
        },
      },
    },
  },
} as Theme;
