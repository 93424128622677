import { KeyboardKey } from 'components/Shared/NumericKeypad/NumericKeypad';

export const Keypad: KeyboardKey[] = [
  {
    displayName: '1',
    value: '1',
  },
  {
    displayName: '2',
    value: '2',
  },
  {
    displayName: '3',
    value: '3',
  },
  {
    displayName: '4',
    value: '4',
  },
  {
    displayName: '5',
    value: '5',
  },
  {
    displayName: '6',
    value: '6',
  },
  {
    displayName: '7',
    value: '7',
  },
  {
    displayName: '8',
    value: '8',
  },
  {
    displayName: '9',
    value: '9',
  },
  {
    displayName: '00',
    value: '00',
  },
  {
    displayName: '0',
    value: '0',
  },
];

export const DefaultQuickCash: number[] = [0.05, 0.1, 0.2, 0.5, 1, 2, 5, 10, 20, 50, 100];

export const PaymentModalAnimations = {
  variants: {
    slideUp: {
      opacity: 0,
      y: '3vh',
    },
    slideDown: {
      opacity: 0,
      y: '-3vh',
    },
    onPlace: {
      y: 0,
      opacity: 1,
    },
  },
  transition: { type: 'tween', ease: 'easeInOut', duration: 0.25 },
};

export type TipAmountType = 'no-tip' | 'full-tip' | 'adjust-tip';
