import React, { Suspense, lazy } from 'react';

import { useAppSelector } from 'hooks/useRedux';
import { Routes, Route, Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const LoginFailed = lazy(() => import('components/Auth/LoginFailed'));
const PostLogin = lazy(() => import('components/Auth/PostLogin'));
const AppConfigComponent = lazy(() => import('containers/Authorize/AppConfigComponent'));
const LoginWrapper = lazy(() => import('containers/Authorize/LoginWrapper'));
const FirstTimeLaunch = lazy(() => import('containers/FirstTimeLaunch/FirstTimeLaunch'));
const CustomerDisplay = lazy(() => import('containers/CustomerDisplay/CustomerDisplay'));
const MainContainer = lazy(() => import('../MainContainer/MainContainer'));

const RoutesComponent = () => {
  const { showFirstTimeLaunch } = useAppSelector((state) => state.config);

  const DefaultRedirect = () => {
    return showFirstTimeLaunch ? <Navigate to="./first-time-launch" /> : <Navigate to="./app-config" />;
  };

  return (
    <Suspense fallback={<CircularProgress />}>
      <Routes>
        <Route path="/" element={<DefaultRedirect />} />
        <Route path="/login-redirect" element={<PostLogin />} />
        <Route path="/login-failed" element={<LoginFailed />} />
        <Route path="/app-config" element={<AppConfigComponent />} />
        <Route path="/cashier-login" element={<LoginWrapper />} />
        <Route path="/first-time-launch" element={<FirstTimeLaunch />} />
        <Route path="/customer-display" element={<CustomerDisplay />} />
        <Route path="*" element={<MainContainer />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesComponent;
