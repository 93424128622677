import { createSelector } from '@reduxjs/toolkit';
import {
  AvailableIntakeContents,
  deliveryTypes,
  extendedDeliveryTypes,
  PickUpTypesValues,
} from 'containers/Intake/IntakeConsts';
import { RootState } from 'stores';
import { getFeatureFlags, getPayOnAccountCode } from 'stores/Config/config.selector';
import { PaymentMethod, PaymentMethodCode } from 'typings/Payments';

export const getIsEditMode = ({ intake }: RootState) => intake.editMode;

export const canEditBasket = ({ intake }: RootState) => (intake.editMode ? intake.editMode.canEditBasket : true);

export const getIsBasketEdited = ({ intake }: RootState) => intake.editMode?.basketEdited ?? false;

export const canChangePayment = ({ intake }: RootState) =>
  intake.editMode ? intake.editMode.canChangePayment : true;

export const getAvailableIntakeContents = () =>
  createSelector([getFeatureFlags, getIsEditMode], (featureFlags, editMode) => {
    const availableIntakeContents: AvailableIntakeContents[] = [
      AvailableIntakeContents.Products,
      AvailableIntakeContents.Coupons,
    ];

    if (featureFlags.OfflineModule_ShowRewardsOnPos) {
      availableIntakeContents.push(AvailableIntakeContents.Rewards);
    }

    if (!editMode) {
      if (featureFlags.OfflineModule_Restaurant) {
        availableIntakeContents.push(AvailableIntakeContents.DineInOpenOrders);
      }

      if (featureFlags.OfflineModule_UseFloorPlanConfigurator) {
        availableIntakeContents.push(AvailableIntakeContents.Tables);
      }
    }

    return availableIntakeContents;
  });

export const getAvailableDeliveryTypes = ({ intake }: RootState) => {
  const result =
    intake.editMode?.mode === 'full'
      ? deliveryTypes.filter((el) => el.value !== PickUpTypesValues.takeAway)
      : deliveryTypes;

  const excludedDeliveryTypes = intake.onSiteSettings?.excludedDeliveryTypes || [];
  const includedDeliveryTypes = getExtendedDeliveryTypes(intake.editMode?.mode)
    .filter((el) => excludedDeliveryTypes.indexOf(el.key) === -1)
    .map((el) => el.deliveryType);

  return result.filter((el) => includedDeliveryTypes.indexOf(el.value) > -1);
};

export const getExtendedAvailableDeliveryTypes = ({ intake }: RootState) => {
  const deliveryTypes = getExtendedDeliveryTypes(intake.editMode?.mode);

  return deliveryTypes.filter(
    (el) => intake.onSiteSettings && intake.onSiteSettings.excludedDeliveryTypes.indexOf(el.key) === -1,
  );
};

export const getIsTakeAwayAvailable = ({ intake }: RootState) =>
  !intake.onSiteSettings || intake.onSiteSettings.excludedDeliveryTypes.indexOf(PickUpTypesValues.takeAway) === -1;

export const getIsEatInAvailable = ({ intake }: RootState) =>
  !intake.onSiteSettings || intake.onSiteSettings.excludedDeliveryTypes.indexOf(PickUpTypesValues.eatIn) === -1;

const getExtendedDeliveryTypes = (mode: string | undefined) =>
  mode === 'full'
    ? extendedDeliveryTypes.filter((el) => el.deliveryType !== PickUpTypesValues.takeAway)
    : extendedDeliveryTypes;

const getAvailablePaymentMethods = ({ payments }: RootState) => payments.availablePaymentMethods;

export const getActiveDeliveryType = ({ intake }: RootState) => intake.activeDeliveryType;

const getCustomerCanPayOnAccount = ({ customer }: RootState) => customer.customerCanPayOnAccount;

const getChargedCompanyWhenPayingOnAccount = ({ customer }: RootState) => customer.chargedCompany;

export const getPaymentMethodsToShow = () =>
  createSelector(
    [
      getPayOnAccountCode,
      getAvailablePaymentMethods,
      getActiveDeliveryType,
      getCustomerCanPayOnAccount,
      getChargedCompanyWhenPayingOnAccount,
    ],
    (
      payOnAccountMethodCode,
      availablePaymentMethods,
      activeDeliveryType,
      customerCanPayOnAccount,
      chargedCompanyWhenPayingOnAccount,
    ) => {
      let showablePaymentMethods: PaymentMethod[] =
        availablePaymentMethods?.map((method) => ({ ...method })) ?? [];

      const payOnAccountPaymentMethod = showablePaymentMethods.find(
        (method) => method.code === payOnAccountMethodCode,
      );

      if (
        customerCanPayOnAccount &&
        activeDeliveryType !== PickUpTypesValues.dineIn &&
        payOnAccountPaymentMethod
      ) {
        if (activeDeliveryType === PickUpTypesValues.pickUp) {
          showablePaymentMethods =
            showablePaymentMethods?.filter((method) => method.code === payOnAccountMethodCode) ?? [];
          const artificialPaymentMethod = {
            code: PaymentMethodCode.PayInStoreArtificial,
            description: 'Pay in store',
          } as PaymentMethod;
          showablePaymentMethods.push(artificialPaymentMethod);
        }

        payOnAccountPaymentMethod.methodRemark = chargedCompanyWhenPayingOnAccount?.name;
      } else {
        showablePaymentMethods =
          showablePaymentMethods?.filter((method) => method.code !== payOnAccountMethodCode) ?? [];
      }

      return showablePaymentMethods;
    },
  );
