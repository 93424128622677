/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FloorEntity } from 'typings/Tables';
import { loadFloors, saveFloors } from './floors.thunk-actions';

const floorsAdapter = createEntityAdapter<FloorEntity>();

const floorsSlice = createSlice({
  name: '[FLOORS]',
  initialState: floorsAdapter.getInitialState({ isLoading: false }),
  reducers: {
    resetFloorsState: () => floorsAdapter.getInitialState({ isLoading: false }),
    setFloors(state, action: PayloadAction<FloorEntity[]>) {
      floorsAdapter.removeAll(state);
      floorsAdapter.addMany(state, action.payload);
    },
    updateFloor(state, action: PayloadAction<FloorEntity>) {
      floorsAdapter.upsertOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveFloors.rejected, () => {
      console.error('There was an error preventing saving floor plan to the server');
    });
    builder.addCase(loadFloors.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(loadFloors.fulfilled, (state, action) => {
      const parsedFloors = JSON.parse(action.payload) as FloorEntity[];
      floorsAdapter.addMany(state, parsedFloors);
      state.isLoading = false;
    });
    builder.addCase(loadFloors.rejected, (state) => {
      console.error('There was an error loading floor plan from the server');
      return { ...state, isLoading: false };
    });
  },
});

const { reducer, actions } = floorsSlice;
export const floorsSelectors = floorsAdapter.getSelectors();
export const { setFloors, updateFloor, resetFloorsState } = actions;
export type FloorsState = ReturnType<typeof reducer>;

export default reducer;
