export interface EftPaymentState {
  eftStatusResponse?: EftStatusResponse;
  cancelationInProgress?: boolean;
  eftAcceptanceInProgress?: boolean;
  manualConfirmInProgress?: boolean;
  abandonInProgress?: boolean;
  startInProgress?: boolean;
  eftPaymentId?: string;
  startError?: string;
  automaticFinalizationInProgress?: boolean;
}

export interface EftStatusResponse {
  status: EftPaymentStatus;
  startPaymentErrorCode?: StartPaymentError;
  possibleOperations: EftPaymentOperation[];
  isFinalizedByProvider: boolean;
  authorizedAmount?: number;
}
export interface OrderPaymentStatusResponse {
  isFinalized: boolean;
  receivedFullAmount: boolean;
  leftToPay: number;
  paidAmount: number;
  eftPayments: {
    id: string;
    amount: number;
    paymentMethodId: number;
    isCompleted: boolean;
  }[];
  manualPayments: { id: string; amount: number; paymentMethodId: number }[];
}

export interface OrderPaymentStatusData {
  isPaid: boolean;
  leftToPay: number;
  paidAmount: number;
  payments: SplitPaymentData[];
}

export interface SplitPaymentData {
  paymentId: string;
  paymentMethodId: number;
  amount: number;
  isCompleted: boolean;
}

export interface EftCancelationResult {
  externalCallResult: ExternalCallResult;
  errorCode?: CancelPaymentError;
}

export enum EftPaymentStatus {
  PendingToStart = 'PendingToStart', // 1
  Abandoned = 'Abandoned', // 2,
  Started = 'Started', // 3,
  /// <summary>
  /// Payment was confirmed by the provider as successful
  /// </summary>
  Successful = 'Successful', // 4,
  /// <summary>
  /// Payment could not be started by the provider. Currently covers both errors and timeouts - possibly should be split
  /// </summary>
  FailedToStart = 'FailedToStart', // 5,
  /// <summary>
  /// Payment was started, but was not completed successfully by the provider
  /// </summary>
  Failed = 'Failed', // 6,

  /// <summary>
  /// Payment was started, but then cancelled
  /// </summary>
  Cancelled = 'Cancelled', // 7,

  /// <summary>
  /// Payment fully processed successfully
  /// </summary>
  Accepted = 'Accepted', // ,

  // Consider adding "StartingInterrupted" for timeouts
  /// <summary>
  /// Payment was started, but its status was not updated from the provider
  /// </summary>
  TimedOut = 'TimedOut', // ,
  /// <summary>
  /// Payment cancellation process started
  /// </summary>
  CancellationRequested = 'CancellationRequested', // ,
}

export enum StartPaymentError {
  ProviderUnreachable = 'ProviderUnreachable',
  ProviderConnectionInterrupted = 'ProviderConnectionInterrupted',
  InvalidDeviceId = 'InvalidDeviceId',
  EftDeviceBusy = 'EftDeviceBusy',
  EftDeviceUnreachable = 'EftDeviceUnreachable',
  ConfigurationIssue = 'ConfigurationIssue',
  OtherIssue = 'Other',
  TransactionNotAllowed = 'TransactionNotAllowed',
  TransactionRefused = 'TransactionRefused',
}

export enum EftPaymentOperation {
  RetryStart = 'RetryStart',
  Abandon = 'Abandon',
  ConfirmManually = 'ConfirmManually',
  Accept = 'Accept',
  Exit = 'Exit',
  Cancel = 'Cancel',
}

export enum ExternalCallResult {
  Success = 'Success',
  Timeout = 'Timeout',
  Error = 'Error',
}

export enum CancelPaymentError {
  ProviderUnreachable,
  ProviderConnectionInterrupted,
  InvalidDeviceId,
  UnknownPayment,
  CannotCancelPayment,
}
