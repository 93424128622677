import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Box, Drawer, Typography } from '@mui/material';
import DevModePanel from 'App/DevMode/DevModePanel';

const DevModeContext = createContext<IDevMode>({
  showDevUI: false,
  showComponentDevUI: false,
  setShowDevUI: () => null,
});

type IDevMode = {
  showDevUI: boolean;
  showComponentDevUI: boolean;
  setShowDevUI: (v: boolean) => void;
};

const DevModeProvider: React.FC = ({ children }) => {
  const [showDevUI, setShowDevUI] = useState<boolean>(false);
  const [showComponentDevUI, setShowComponentDevUI] = useState<boolean>(false);

  const data = { showDevUI, setShowDevUI, showComponentDevUI };

  const dataPersisted = useMemo(() => data, [showDevUI, setShowDevUI, showComponentDevUI, setShowComponentDevUI]);

  useEffect(() => {
    document.onkeydown = (e: KeyboardEvent) => {
      const isDevelopmentMode = process.env.NODE_ENV === 'development';

      if (isDevelopmentMode) {
        if (e?.key?.toLowerCase() === 'b' && e?.ctrlKey) {
          setShowDevUI(!showDevUI);
        }
      }

      if (e?.key === 'ArrowDown' && e?.ctrlKey) {
        setShowComponentDevUI(true);
      }
      if (e?.key === 'ArrowUp' && e?.ctrlKey) {
        setShowComponentDevUI(false);
      }
    };

    return () => {
      document.onkeydown = () => null;
    };
  }, []);

  return (
    <DevModeContext.Provider value={dataPersisted}>
      <Drawer
        sx={{ zIndex: 1300 }}
        anchor="right"
        open={showDevUI}
        onClose={() => {
          setShowDevUI(!showDevUI);
        }}
      >
        <Box sx={{ minWidth: '35vw', p: 5 }}>
          <Typography variant="h6">Developer Toolbar</Typography>
          <DevModePanel />
        </Box>
      </Drawer>

      {children}
    </DevModeContext.Provider>
  );
};

export { DevModeContext, DevModeProvider };
