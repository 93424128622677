import { generateW3CId } from '@microsoft/applicationinsights-core-js';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import posApi from 'API/PosApi';
import CmsHandler from 'App/CmsHandler/CmsHandler';
import { AxiosError } from 'axios';
import { BrowserHistory } from 'history';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getOnsiteMachine } from 'stores/Auth';
import { automaticCashierLogOut, getLoggedInCashier } from 'stores/Cashier/cashier.thunk-actions';
import {
  getAppVersion,
  getFirstTimeLaunchInfo,
  loadAllConfig,
  loadFeatureFlags,
} from 'stores/Config/config.thunk-actions';
import {
  hideIntakeFinalizeSuccessMessage,
  setCashierIsAway,
  showIntakeFinalizeSuccessMessage,
  updateCustomerDisplayBasket,
} from 'stores/CustomerDisplay';
import {
  setIsHybridModeActive,
  setPhoneNumberCalling,
  setTransferredIntake,
  updateCloudPosAvailabilityStatus,
  updateCloudPosLastConnectionAttempt,
} from 'stores/HybridMode';
import { reconnectToNotifications } from 'stores/Notifications';
import { addClientToStoreGroup } from 'stores/Notifications/notifications.thunk-actions';
import { fetchStaticContents } from 'stores/combined.actions';
import { LocalStorageAuthConsts } from 'typings/Cashier';
import {
  BasketRecalculated,
  CloudPosAvailabilityChangedEvent,
  CloudPosLastConnectionAttemptEvent,
  HybridModeActivityChangedEvent,
  HybridModeMessages,
  LanguageChanged,
  PhoneCalled,
  TransferredIntake,
} from 'typings/HybridMode';
import { saveLastRefreshTimestamp } from 'utils/applicationContainer/refreshAppUtils';
import {
  getHybridModeCommunicator,
  isHybridModeCommunicationAvailable,
} from 'utils/hybridMode/hybridModeCommunicationUtils';
import { LocalStorageItems, getLocalStorageItem } from 'utils/localStorageUtils';

interface AppInitializationWrapperProps {
  children: ReactElement;
  fallback: ReactElement;
  reactPlugin: ReactPlugin;
  browserHistory: BrowserHistory;
}
let appInsights: ApplicationInsights;

const AppInitializationWrapper: React.FC<AppInitializationWrapperProps> = ({
  children,
  fallback,
  reactPlugin,
  browserHistory,
}) => {
  const {
    configLoaded,
    loggingCorrelationId,
    showFirstTimeLaunch,
    instrumentationKey,
    useNotifications,
    instanceType,
    cmsUiSettings,
  } = useAppSelector((state) => state.config);
  const { postPageRedirectStatus } = useAppSelector((state) => state.authorization);
  const { selectedStore } = useAppSelector((state) => state.stores);
  const { notificationsConnectionId } = useAppSelector((state) => state.notifications);
  const { loggedCashier } = useAppSelector((state) => state.cashier);
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const isCustomerDisplayEnabled = getLocalStorageItem<boolean>(LocalStorageItems.useCustomerDisplay) ?? false;

  const handleLanguageChanged = useCallback(() => {
    dispatch(fetchStaticContents());
  }, []);

  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChanged);
    dispatch(getAppVersion());
    dispatch(getLoggedInCashier());
    dispatch(loadFeatureFlags());
    dispatch(loadAllConfig());
    dispatch(getFirstTimeLaunchInfo());

    storeRedirectQueryParams();
    setInnerHeightCssProperty();

    initHybridCommunication();

    window.addEventListener('resize', () => {
      setInnerHeightCssProperty();
    });

    window.addEventListener('orientationchange', () => {
      setInnerHeightCssProperty();
    });

    posApi.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          dispatch(automaticCashierLogOut());

          appInsights.trackEvent({
            name: `[AutoSignout][InactivityGuard] User signed out due to 401 returned from api`,
          });
        }
        return error;
      },
    );
    saveLastRefreshTimestamp(new Date().getTime());

    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, []);

  useEffect(() => {
    if (loggingCorrelationId && appInsights) {
      appInsights.flush();
      appInsights.context.telemetryTrace.traceID = generateW3CId();
    }
  }, [loggingCorrelationId]);

  useEffect(() => {
    if (instanceType) {
      dispatch(getOnsiteMachine());
    }
  }, [postPageRedirectStatus, instanceType]);

  useEffect(() => {
    if (instrumentationKey) {
      loadAppInsights();
    }
  }, [instrumentationKey]);

  function initHybridCommunication() {
    if (isHybridModeCommunicationAvailable()) {
      const hybridModeCommunicator = getHybridModeCommunicator();

      if (isCustomerDisplayEnabled) {
        hybridModeCommunicator.send(HybridModeMessages.Hybrid.Commands.OpenCustomerDisplay);
      }

      setInterval(() => {
        hybridModeCommunicator.send(HybridModeMessages.Hybrid.Events.PosRunning);
      }, 1000);

      hybridModeCommunicator.subscribe(
        HybridModeMessages.Hybrid.Events.HybridModeActivityChanged,
        (payload: string) => {
          const hybridModeActivity = JSON.parse(payload) as HybridModeActivityChangedEvent;

          dispatch(setIsHybridModeActive(hybridModeActivity));
        },
      );

      hybridModeCommunicator.subscribe(
        HybridModeMessages.Hybrid.Events.CloudPosAvailabilityChanged,
        (payload: string) => {
          const cloudPosAvailability = JSON.parse(payload) as CloudPosAvailabilityChangedEvent;

          dispatch(updateCloudPosAvailabilityStatus(cloudPosAvailability?.isAvailable ?? false));
        },
      );

      hybridModeCommunicator.subscribe(
        HybridModeMessages.Hybrid.Events.CloudPosLastConnectionAttemptEvent,
        (payload: string) => {
          const lastAttempt = JSON.parse(payload) as CloudPosLastConnectionAttemptEvent;

          if (lastAttempt) {
            dispatch(updateCloudPosLastConnectionAttempt(new Date(lastAttempt.timestampInMiliseconds)));
          }
        },
      );

      hybridModeCommunicator.subscribe(HybridModeMessages.Hybrid.Commands.RestoreBasket, (payload: string) => {
        const intakeStateToTransfer = JSON.parse(payload) as TransferredIntake;

        if (intakeStateToTransfer) {
          dispatch(setTransferredIntake(intakeStateToTransfer));
        }
      });

      hybridModeCommunicator.subscribe(HybridModeMessages.Hybrid.Events.BasketRecalculated, (payload: string) => {
        const basketRecalculatedEvent = JSON.parse(payload) as BasketRecalculated;

        if (basketRecalculatedEvent) {
          dispatch(updateCustomerDisplayBasket(basketRecalculatedEvent));
          dispatch(hideIntakeFinalizeSuccessMessage());
        }
      });

      hybridModeCommunicator.subscribe(HybridModeMessages.Hybrid.Events.IntakeFinalized, () => {
        dispatch(showIntakeFinalizeSuccessMessage());
      });

      hybridModeCommunicator.subscribe(HybridModeMessages.Hybrid.Events.CashierLoggedIn, () => {
        dispatch(setCashierIsAway());
      });

      hybridModeCommunicator.subscribe(HybridModeMessages.Hybrid.Events.CashierLoggedOut, () => {
        dispatch(setCashierIsAway(true));
      });

      hybridModeCommunicator.subscribe(HybridModeMessages.Hybrid.Events.LanguageChanged, (payload: string) => {
        const languageChangedEvent = JSON.parse(payload) as LanguageChanged;

        if (languageChangedEvent) {
          i18n.changeLanguage(languageChangedEvent.languageCode);
        }
      });

      hybridModeCommunicator.subscribe(HybridModeMessages.Hybrid.Events.PhoneCalled, (payload: string) => {
        const phoneCalledEvent = JSON.parse(payload) as PhoneCalled;

        if (phoneCalledEvent) {
          dispatch(setPhoneNumberCalling(phoneCalledEvent.phoneNumber));
        }
      });
    }
  }

  async function loadAppInsights(): Promise<void> {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      // eslint-disable-next-line no-param-reassign
      envelope.tags = envelope.tags || [];
      envelope.tags.push({
        'ai.cloud.role': 'offline-module-ui',
      });
    });
  }

  useEffect(() => {
    if (useNotifications && selectedStore && loggedCashier) {
      dispatch(reconnectToNotifications());
    }
  }, [useNotifications, loggedCashier, selectedStore]);

  useEffect(() => {
    if (notificationsConnectionId) {
      dispatch(addClientToStoreGroup());
    }
  }, [notificationsConnectionId]);

  const storeRedirectQueryParams = (): void => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.get('system') && searchParams.get('redirect-url')) {
      localStorage.setItem(LocalStorageAuthConsts.AuthQuery, window.location.search);
    }
  };

  const setInnerHeightCssProperty = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  const dataReady = configLoaded && showFirstTimeLaunch !== undefined;

  if (dataReady)
    return (
      <>
        {children}
        {cmsUiSettings?.useCms && <CmsHandler />}
      </>
    );
  return fallback;
};

export default AppInitializationWrapper;

export const getAppInsights = () => appInsights;
