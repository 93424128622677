import {
  CustomizationProduct,
  Customization,
  ToppingSelection,
  Topping,
  ProductSetStepSelection,
  ProductSetCustomization,
} from 'typings/Products';

export function mapToCustomization(
  customizationProduct: CustomizationProduct,
  productSetCustomization: ProductSetCustomization | undefined,
): Customization {
  return {
    sliceNumber: 1,
    selectedPizza: customizationProduct.baseProduct,
    selectedToppings: productSetCustomization?.selections.flatMap((ts) => ts.selectedToppings),
    rands: [],
    sauces: [],
    stepSelections: productSetCustomization?.selections,
  } as Customization;
}

export function isProductSetComplete(
  customizationProduct: CustomizationProduct,
  productSetCustomization?: ProductSetCustomization,
): boolean {
  const setCustomization = productSetCustomization ?? {
    selections: customizationProduct.stepSelections ?? [],
    selectedOptionId: customizationProduct.originalOptionId,
    quantity: customizationProduct.quantity,
  };

  const stepsDefinitions = customizationProduct.baseProduct.toppingSelectionSteps;

  if (!stepsDefinitions) {
    return false;
  }

  if (
    stepsDefinitions.length > 0 &&
    (!setCustomization ||
      setCustomization.selections.length <
        stepsDefinitions.filter((step) => step.requiredAmountOfToppings > 0).length)
  ) {
    return false;
  }

  if (!setCustomization.selectedOptionId) {
    return false;
  }

  const areThereAnyIncompleteSteps = setCustomization.selections.some((stepSelection) => {
    const matchingStepDefinition = stepsDefinitions.find((sd) => sd.order === stepSelection.order);

    if (!matchingStepDefinition) {
      return true;
    }

    const numberOfSelectedToppings = getNumberOfSelectedToppings(stepSelection.selectedToppings);

    if (numberOfSelectedToppings < matchingStepDefinition.requiredAmountOfToppings) {
      return true;
    }

    return false;
  });

  return !areThereAnyIncompleteSteps;
}

export function getNumberOfSelectedToppings(selectedToppings: ToppingSelection[]): number {
  if (selectedToppings.length === 0) {
    return 0;
  }

  return selectedToppings
    .map((topping) => topping.quantity ?? 0)
    .reduce((totalValue, currentValue) => totalValue + currentValue);
}

export function createInitialCustomization(
  customizationProduct: CustomizationProduct,
  toppings: Topping[],
  quantity: number,
  optionId: number | null,
): ProductSetCustomization {
  if (customizationProduct.stepSelections)
    return {
      quantity,
      selections: sortProductStepSelectionByOrderNumber(
        customizationProduct.stepSelections.map((ss) => {
          return { ...ss };
        }),
      ),
      selectedOptionId: optionId,
    } as ProductSetCustomization;

  if (customizationProduct.sliceCustomizations) {
    const selectedToppings =
      customizationProduct.sliceCustomizations.flatMap((sc) => sc.toppingsConfiguration) ?? [];
    let availableSelectedTopingsIds = getPassedProductSelectedToppingsIds(customizationProduct);
    const selections =
      customizationProduct.baseProduct?.toppingSelectionSteps?.map((step) => {
        const stepAvailableToppingsIds = step.availableToppings.map((at) => at.toppingId);
        const alreadySelectedStepToppingsIds = availableSelectedTopingsIds.filter((tid) =>
          stepAvailableToppingsIds.includes(tid),
        );
        availableSelectedTopingsIds = availableSelectedTopingsIds.filter(
          (tid) => !stepAvailableToppingsIds.includes(tid),
        );
        const stepToppingsSelection =
          alreadySelectedStepToppingsIds.length === 0
            ? []
            : toppings
                .filter((t) => alreadySelectedStepToppingsIds.includes(t.id))
                .map(
                  (t) =>
                    ({
                      ...t,
                      quantity: selectedToppings.find((st) => st.toppingId === t.id)?.quantity ?? 0,
                    } as ToppingSelection),
                );

        return {
          order: step.order,
          selectedToppings: stepToppingsSelection,
          title: step.title,
        } as ProductSetStepSelection;
      }) ?? [];

    return {
      quantity,
      selections: sortProductStepSelectionByOrderNumber(selections),
      selectedOptionId: optionId,
    } as ProductSetCustomization;
  }

  const selections =
    customizationProduct.baseProduct?.toppingSelectionSteps?.map(
      (step) =>
        ({
          order: step.order,
          selectedToppings: [],
          title: step.title,
        } as ProductSetStepSelection),
    ) ?? [];

  return {
    quantity,
    selections: sortProductStepSelectionByOrderNumber(selections),
    selectedOptionId: optionId,
  } as ProductSetCustomization;
}

function sortProductStepSelectionByOrderNumber(selections: ProductSetStepSelection[]): ProductSetStepSelection[] {
  return selections.sort((a, b) => a.order - b.order);
}

export function getPassedProductSelectedToppingsIds(customizationProduct: CustomizationProduct): number[] {
  return (
    customizationProduct.sliceCustomizations
      ?.flatMap((sc) => sc.toppingsConfiguration)
      ?.flatMap((tc) => tc.toppingId) ?? []
  );
}

export function createTabsSetSteps(customizationProduct: CustomizationProduct) {
  if (!customizationProduct.baseProduct.toppingSelectionSteps) {
    return [];
  }

  return [...customizationProduct.baseProduct.toppingSelectionSteps].sort((a, b) => a.order - b.order);
}

export function mapCustomizationsToStepSelections(
  customizations: Customization[],
): ProductSetStepSelection[] | undefined {
  const productSetCustomizationsWithSelections = customizations.filter((c) => c.stepSelections !== undefined);

  if (productSetCustomizationsWithSelections.length === 0) {
    return undefined;
  }

  return productSetCustomizationsWithSelections.flatMap((c) => c.stepSelections ?? []);
}
