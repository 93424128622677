import React from 'react';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';

export interface ScannerSimulatorForm {
  scanInput: string;
}

const ScannerSimulator: React.FC = () => {
  const [t] = useTranslation('common');

  const scannerSimulatorForm = useForm<ScannerSimulatorForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      scanInput: '',
    },
  });

  const { errors, control, reset, getValues } = scannerSimulatorForm;

  function simulateScan(scannerInput: string): void {
    if (!scannerInput) return;
    const inputLength = scannerInput.length;
    if (inputLength < 1) return;
    if (inputLength > 500) return;
    for (let i = 0; i < inputLength; i += 1) {
      document.dispatchEvent(new KeyboardEvent('keypress', { key: scannerInput.charAt(i) }));
      document.dispatchEvent(new KeyboardEvent('keydown', { key: scannerInput.charAt(i) }));
      document.dispatchEvent(new KeyboardEvent('keyup', { key: scannerInput.charAt(i) }));
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        border: '1px solid grey',
        borderRadius: '8px',
        p: 1,
        my: 1,
      }}
    >
      <Typography variant="h5">Scanner simulator</Typography>
      <Box>
        <Typography variant="body2" data-testid="settle-payment__label--eft-success">
          {t('Enter scanner value')}
        </Typography>
        <Controller
          defaultValue=""
          control={control}
          name="scanInput"
          render={(field) => (
            <TextField
              type="text"
              variant="outlined"
              label="Scanner input to simulate"
              fullWidth
              error={errors.scanInput && true}
              helperText={errors.scanInput && errors.scanInput.message}
              InputProps={{
                sx: { px: 2, py: 2, fontSize: 20, fontWeight: 500, color: 'grey' },

                endAdornment: (
                  <InputAdornment position="end">
                    <CloseIcon
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        reset({ scanInput: '' });
                      }}
                    />
                  </InputAdornment>
                ),
                inputProps: {
                  maxLength: '20',
                  autoFocus: true,
                  style: {
                    padding: '0',
                  },
                  'data-testid': `dev-mode-scanner-input`,
                },
              }}
              {...field}
            />
          )}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, p: 1 }}>
          <Button
            onClick={() => {
              simulateScan(getValues().scanInput);
            }}
            variant="outlined"
            size="small"
          >
            {t('Simulate scan input')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default ScannerSimulator;
