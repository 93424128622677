import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from 'locales/i18nResources';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    saveMissing: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    appendNamespaceToMissingKey: true,
    parseMissingKeyHandler: (key, value) => {
      const isJustNamespace = key.split(':')[1] === '';
      if (!isJustNamespace) {
        // eslint-disable-next-line no-console
        console.debug(`[I18N] Cannot find any value for key:'${key}'`);
        return key.split(':')[1];
      }
      return key;
    },
  });

export default i18n;
