import { CSSObject } from '@mui/material/styles';

import { BASE_THEME } from './base.theme.css';

const PH_COLORS = {
  primary: {
    main: '#6B6E79',
    light: '#B5B6BD',
    dark: '#53555e',
    contrastText: '#fff',
  },
  error: {
    ...BASE_THEME.palette.error,
    main: '#C8102E',
    dark: '#6f0094',
    light: '#df80ff',
  },
  success: {
    light: '#81c784',
    main: '#0A8020',
    dark: '#4C6F01',
    contrastText: '#fff',
  },
};

export const PIZZA_HUT = {
  ...BASE_THEME,
  palette: {
    ...BASE_THEME.palette,
    ...PH_COLORS,
    virtualReceipt: {
      items: {
        discount: {
          ...BASE_THEME.palette.virtualReceipt.items.discount,
          error: {
            icon: 'rgb(255,41,41)',
            background: PH_COLORS.error.light,
          },
        },
      },
    },
  },
  components: {
    ...BASE_THEME.components,
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(16px, 12px) scale(1)',
        },
        shrink: {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ...(BASE_THEME.components?.MuiCssBaseline?.styleOverrides as CSSObject),
        '::-webkit-scrollbar-thumb': {
          background: '#6B6E79',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: '#6B6E79',
        },
      },
    },
  },
};
