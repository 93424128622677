import React, { useEffect, useState } from 'react';
import { ButtonBase, Box, useTheme } from '@mui/material';
import noOptionImage from 'assets/images/no-option-image-grey.png';
import { Option } from 'typings/Products';
import { CheckCircle } from '@mui/icons-material';
import buildClasses from './ProductOptions.css';

interface ProductOptionsProps {
  options: Option[];
  activeOptionId: number | null;
  activeCategoryCode: string;
  onOptionChange: (optionId: number) => void;
}

const ProductOptions: React.FC<ProductOptionsProps> = ({
  options,
  activeOptionId,
  activeCategoryCode,
  onOptionChange,
}) => {
  const { classes, cx } = buildClasses();
  const { sizing, spacing, palette, radius } = useTheme();
  const [showFallbackImage, setShowFallbackImage] = useState(false);

  useEffect(() => {
    if (
      (!activeOptionId && options.length > 0) ||
      (options.find((el) => el.id === activeOptionId) === undefined && options.length > 0)
    ) {
      onOptionChange(options[0].id);
    }
  }, [options]);

  function optionImageUrl(url: string): string {
    if (!url) {
      return noOptionImage;
    }
    return url;
  }

  if (!options || options.length === 0) return null;

  return (
    <Box
      sx={{
        backgroundColor: `${palette.secondary.light}`,
        display: 'flex',
        flexDirection: 'row',
        marginTop: spacing(3),
        width: sizing.full,
        borderRadius: radius.sm,
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        padding: spacing(1),
      }}
      data-testid="product-selection__container--options"
    >
      {options &&
        options.map((option) => (
          <ButtonBase
            className={cx(classes.buttonBox, { [classes.activeButtonBox]: activeOptionId === option.id })}
            key={`option_${option.id}`}
            onClick={(): void => {
              onOptionChange(option.id);
            }}
            data-testid={`product-options__button--${option.title}`}
          >
            {option.imageUrl && !showFallbackImage ? (
              <img
                className={classes.image}
                src={optionImageUrl(option.imageUrl)}
                alt={option.title}
                onError={(): void => {
                  console.warn('image error');
                  setShowFallbackImage(true);
                }}
              />
            ) : (
              <div
                className={cx(classes.buttonBoxSubCategory, {
                  [classes.activeButtonBoxSubCategory]: activeOptionId === option.id,
                })}
              >
                <span>{activeCategoryCode}</span>
              </div>
            )}
            <span className={classes.optionButton}>{option.name}</span>
            {activeOptionId === option.id && <CheckCircle className={classes.activeIcon} />}
          </ButtonBase>
        ))}
    </Box>
  );
};

export default ProductOptions;
