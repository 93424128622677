import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BasketState } from 'typings/Basket';
import { recalculateBasket, validateBasket } from './basket.thunk-actions';

export const initialState: BasketState = {
  autoAddedItems: [],
  virtualReceipt: { receiptDiscounts: [], receiptProducts: [] },
  basketItems: [],
  basketCoupons: [],
  basketData: null,
  manualDeliveryCharge: null,
  lastBasketClearTimestamp: Date.now(),
};

const basketSlice = createSlice({
  name: '[BASKET]',
  initialState,
  reducers: {
    restartBasketState: () => {
      return { ...initialState, lastBasketClearTimestamp: Date.now() };
    },
    setBasketState(_, action: PayloadAction<BasketState>) {
      return {
        ...action.payload,
      };
    },
    clearBasket: (state) => ({
      ...state,
      basketItems: [],
      basketCoupons: [],
      calculatedDeliveryCharge: null,
      manualDeliveryCharge: null,
      virtualReceipt: { receiptDiscounts: [], receiptProducts: [] },
      basketData: null,
      basketRecalculateInProgress: undefined,
      lastBasketClearTimestamp: Date.now(),
    }),
    doNotGroupItems(state, action: PayloadAction<number[]>) {
      const items = state.basketItems.map((item) => {
        return {
          ...item,
          doNotGroup: action.payload.includes(item.id),
        };
      });

      return {
        ...state,
        basketItems: items,
      };
    },
    ignoreItemsInPriceCalculations(state, action: PayloadAction<number[]>) {
      const items = state.basketItems.map((item) => {
        return {
          ...item,
          ignoreInPriceCalculation: action.payload.includes(item.id),
        };
      });

      return {
        ...state,
        basketItems: items,
      };
    },
    setManualDeliveryCharge: (state, action: PayloadAction<number | null>) => ({
      ...state,
      manualDeliveryCharge: action.payload,
    }),
    changeAutoItemQuantity(state, action: PayloadAction<{ itemId: number; quantity: number }>) {
      const editedItems = state.autoAddedItems.map((aai) => {
        if (aai.itemId === action.payload.itemId) {
          return { ...aai, quantity: action.payload.quantity, wasEdited: true };
        }
        return aai;
      });

      return {
        ...state,
        autoAddedItems: editedItems,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(validateBasket.fulfilled, (state, action) => {
      return {
        ...state,
        validationResult: action.payload,
      };
    });
    builder.addCase(recalculateBasket.pending, (state) => {
      return {
        ...state,
        recalculateInProgress: true,
        validationResult: undefined,
      };
    });
    builder.addCase(recalculateBasket.rejected, (state) => {
      return {
        ...state,
        recalculateInProgress: undefined,
      };
    });
    builder.addCase(recalculateBasket.fulfilled, (state, action) => {
      if (!action.payload) return state;
      const { basketItems, basketCoupons, basketData, virtualReceipt, addedProducts } = action.payload;

      return {
        ...state,
        basketItems,
        basketCoupons,
        basketData,
        virtualReceipt,
        autoAddedItems: addedProducts,
        recalculateInProgress: undefined,
      };
    });
  },
});

const { reducer } = basketSlice;
export const {
  restartBasketState,
  clearBasket,
  setBasketState,
  doNotGroupItems,
  ignoreItemsInPriceCalculations,
  setManualDeliveryCharge,
  changeAutoItemQuantity,
} = basketSlice.actions;

export default reducer;
