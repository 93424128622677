import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logOutCashier } from 'stores/Cashier';
import {
  getOrderDetails,
  getAllOrders,
  getTableFiltersValues,
  downloadOrders,
  getOrderDetailsForFinalize,
  finalizePaidOrder,
  assignCreditToCustomer,
  cancelOrder,
  checkIfOrderCanBeRefunded,
} from './allOrders-actions';
import { AllOrdersState, StatusTabType } from './allOrders.types';

const initialState: AllOrdersState = {
  currentStoreTab: 'my-store',
  currentStatusTab: 'all-orders',
  orderDetailsPage: 'overview',
  ordersFetchInProgress: false,
  ordersDownloadInProgress: false,
  orderCancelInProgress: false,
  ordersList: [],
  tableOptions: {
    pageSize: 15,
    currentPageIndex: 0,
    sortingDirection: 'asc',
    sortBy: '',
    totalOrders: 0,
  },
  availableChannels: [],
  availablePaymentMethods: [],
  orderDetailsModal: null,
};

const ordersSlice = createSlice({
  name: '[ALL_ORDERS]',
  initialState,
  reducers: {
    restartAllOrdersState: () => initialState,
    resetSelectedOrderDetails: (state) => ({
      ...state,
      selectedOrderDetails: undefined,
      orderDetailsPage: 'overview',
    }),
    resetTableOptions: (state) => ({
      ...state,
      tableOptions: initialState.tableOptions,
    }),
    setOrderDetailsPage: (state, action: PayloadAction<'overview' | 'more-details'>) => ({
      ...state,
      orderDetailsPage: action.payload,
    }),
    setCurrentStoreTab: (state, action: PayloadAction<'my-store' | 'all-stores'>) => ({
      ...state,
      currentStoreTab: action.payload,
    }),
    setCurrentStatusTab: (state, action: PayloadAction<StatusTabType>) => ({
      ...state,
      tableOptions: initialState.tableOptions,
      currentStatusTab: action.payload,
    }),
    setCurrentPageIndex: (state, action: PayloadAction<number>) => ({
      ...state,
      tableOptions: {
        ...state.tableOptions,
        currentPageIndex: action.payload,
      },
    }),
    setPageSize: (state, action: PayloadAction<number>) => ({
      ...state,
      tableOptions: {
        ...state.tableOptions,
        pageSize: action.payload,
      },
    }),
    setSorting: (state, action: PayloadAction<{ sortBy: string; sortingDirection: 'asc' | 'desc' }>) => ({
      ...state,
      tableOptions: {
        ...state.tableOptions,
        sortBy: action.payload.sortBy,
        sortingDirection: action.payload.sortingDirection,
      },
    }),
    resetSorting: (state) => ({
      ...state,
      tableOptions: {
        ...state.tableOptions,
        sortBy: initialState.tableOptions.sortBy,
        sortingDirection: initialState.tableOptions.sortingDirection,
      },
    }),

    setFinishOrderData: (
      state,
      action: PayloadAction<{
        orderId: string;
        totalPay: number;
        canFinalize: boolean;
        isPaid: boolean;
      }>,
    ) => ({
      ...state,
      finishPaidOrderData: action.payload,
    }),
    clearFinishOrderData: (state) => ({
      ...state,
      finishPaidOrderData: undefined,
    }),
    clearOrderDetailsModal: (state) => ({
      ...state,
      orderDetailsModal: null,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getAllOrders.pending, (state) => ({
      ...state,
      ordersFetchInProgress: true,
    }));
    builder.addCase(getAllOrders.fulfilled, (state, action) => ({
      ...state,
      ordersList: action.payload.orders,
      ordersFetchInProgress: false,
      tableOptions: {
        ...state.tableOptions,
        totalOrders: action.payload.totalOrders,
      },
    }));
    builder.addCase(getAllOrders.rejected, (state) => ({
      ...state,
      ordersFetchInProgress: false,
    }));
    builder.addCase(getOrderDetails.fulfilled, (state, action) => ({
      ...state,
      selectedOrderDetails: action.payload,
      ordersList: state.ordersList.map((order) => {
        if (order.orderId === action.payload.id) {
          return { ...order, canCancel: action.payload.canCancel };
        }
        return order;
      }),
    }));
    builder.addCase(getOrderDetailsForFinalize.fulfilled, (state, action) => {
      if (!action.payload) return state;
      const { isPaid, priceSummary, id, canFinalize } = action.payload;

      if (isPaid === false) return state;

      return {
        ...state,
        finishPaidOrderData: {
          orderId: id,
          totalPay: priceSummary.total,
          canFinalize,
        },
      };
    });

    builder.addCase(getTableFiltersValues.fulfilled, (state, action) => ({
      ...state,
      availableChannels: action.payload.channels,
      availablePaymentMethods: action.payload.paymentMethods,
    }));
    builder.addCase(finalizePaidOrder.fulfilled, (state) => ({
      ...state,
      finishPaidOrderData: undefined,
    }));
    builder.addCase(logOutCashier.fulfilled, (_) => ({
      ...initialState,
    }));
    builder.addCase(downloadOrders.pending, (state) => ({
      ...state,
      ordersDownloadInProgress: true,
    }));
    builder.addCase(downloadOrders.fulfilled, (state) => ({
      ...state,
      ordersDownloadInProgress: false,
    }));
    builder.addCase(downloadOrders.rejected, (state) => ({
      ...state,
      ordersDownloadInProgress: false,
    }));
    builder.addCase(cancelOrder.pending, (state) => ({
      ...state,
      orderCancelInProgress: true,
    }));
    builder.addCase(cancelOrder.fulfilled, (state) => ({
      ...state,
      orderCancelInProgress: false,
    }));
    builder.addCase(cancelOrder.rejected, (state) => ({
      ...state,
      orderCancelInProgress: false,
    }));
    builder.addCase(assignCreditToCustomer.fulfilled, (state) => ({
      ...state,
      orderDetailsModal: {
        header: 'Success',
        message: 'Customer credit added',
      },
    }));
    builder.addCase(assignCreditToCustomer.rejected, (state) => ({
      ...state,
      orderDetailsModal: {
        header: 'Failure',
        message: 'Cannot add customer credit',
      },
    }));
    builder.addCase(checkIfOrderCanBeRefunded.pending, (state) => ({
      ...state,
      selectedOrderDetails: state.selectedOrderDetails && {
        ...state.selectedOrderDetails,
        canBeRefunded: undefined,
      },
    }));
    builder.addCase(checkIfOrderCanBeRefunded.fulfilled, (state, action) => ({
      ...state,
      selectedOrderDetails: state.selectedOrderDetails && {
        ...state.selectedOrderDetails,
        canBeRefunded: action.payload.canBeRefund,
      },
    }));
    builder.addCase(checkIfOrderCanBeRefunded.rejected, (state) => ({
      ...state,
      selectedOrderDetails: state.selectedOrderDetails && {
        ...state.selectedOrderDetails,
        canBeRefunded: undefined,
      },
    }));
  },
});

const { reducer } = ordersSlice;

export const {
  setCurrentPageIndex,
  setPageSize,
  setCurrentStatusTab,
  restartAllOrdersState,
  resetSelectedOrderDetails,
  resetTableOptions,
  clearFinishOrderData,
  setFinishOrderData,
  setCurrentStoreTab,
  setOrderDetailsPage,
  setSorting,
  resetSorting,
  clearOrderDetailsModal,
} = ordersSlice.actions;

export default reducer;
