import { CSSObject } from '@mui/material/styles';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { BASE_THEME, genericS4DThemeColors } from './base.theme.css';

export const S4D = {
  ...BASE_THEME,
  palette: {
    ...BASE_THEME.palette,
    appBar: {
      main: BASE_THEME.palette.primary.main,
      subMain: BASE_THEME.palette.primary.dark,
      subMainText: BASE_THEME.palette.primary.contrastText,
    },
  },
  components: {
    ...BASE_THEME.components,
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: `${genericS4DThemeColors.primaryDark}`,
        },
        arrow: {
          color: `${genericS4DThemeColors.primaryDark}`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        colorSecondary: {
          color: 'white',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: genericS4DThemeColors.tertiaryFont,
        },
        secondary: {
          color: genericS4DThemeColors.secondaryDark,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ...(BASE_THEME.components?.MuiCssBaseline?.styleOverrides as CSSObject),
        '::-webkit-scrollbar-thumb': {
          background: genericS4DThemeColors.primaryMain,
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: genericS4DThemeColors.primaryMain,
        },
      },
    },
  },
};
