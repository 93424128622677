import { Typography } from '@mui/material';
import React from 'react';
import buildClass from './ItemQuantity.css';

interface ItemQuantityProps {
  quantity: string | number;
  variant?: 'round' | 'square';
  colorVariant?: 'default' | 'black' | 'transparent';
  icon?: JSX.Element;
  size?: 'sm';
}

const ItemQuantity: React.FC<ItemQuantityProps> = ({ quantity, variant = 'round', icon, colorVariant, size }) => {
  const { classes, cx } = buildClass();

  return (
    <div
      className={cx(classes.itemQuantity, {
        [classes.squareContainer]: variant === 'square',
        [classes.roundContainer]: variant === 'round',
        [classes.black]: colorVariant === 'black',
        [classes.transparent]: colorVariant === 'transparent',
        [classes.roundSm]: variant === 'round' && size === 'sm',
      })}
      key={quantity}
      data-testid="receipt-item__label--item-quantity"
    >
      {icon ?? <Typography variant="subtitle1">{quantity}</Typography>}
    </div>
  );
};

export default ItemQuantity;
