import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import { getOrderDetails } from 'stores/AllOrders';
import { EmbeddedModeOrderEdition, InitEmbeddedModeOrderEditionInput } from './embeddedMode.types';

export const initEmbeddedModeOrderEdition = createAsyncThunk<
  EmbeddedModeOrderEdition,
  InitEmbeddedModeOrderEditionInput,
  { state: RootState }
>('[EMBEDDED_MODE]/initOrderEdition', async (input, { dispatch }) => {
  await dispatch(getOrderDetails({ doFetch: true, publicId: input.orderPublicId }));

  return {
    orderPublicId: input.orderPublicId,
    isOrderPaid: input.isOrderPaid,
    isDataReady: true,
  } as EmbeddedModeOrderEdition;
});
