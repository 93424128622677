import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import posApi, { posApiUrls } from 'API/PosApi';
import { isValidAxiosResponse, isValidSuccessfulAxiosResponse } from 'typings/type-guards';
import {
  formatTimeToOSMDto,
  mapFiltersToOrdersRequest,
  mapOSMDiscountToBasketCoupon,
  mapOSMHistoryToDayHistory,
  mapOSMOrderItem,
  mapToOSMDeliveryAddress,
} from 'utils/orders/ordersUtils';
import { PosApiErrorResponse } from 'typings/shared-types';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import { OrderFilters } from 'containers/AllOrders/AllOrdersTypes';
import {
  OpenOrderStatuses,
  UnpaidOrderStatuses,
  UnpaidOrderStatusesWhenFiscalizationEnabled,
} from 'containers/AllOrders/AllOrderConsts';
import { BasketItem } from 'typings/Basket';
import { generateVirtualReceiptForPaidOrder } from 'stores/Intake/virtualReceiptUtils';
import { Coupon, MealCoupon } from 'typings/Coupons';
import { getPaymentMethods } from 'stores/Payments/payments.thunk-actions';
import { GiftCardActivationDto, GiftCardActivationStatus } from 'typings/Payments/giftCardTypes';
import { GetEftPaymentRefundStatusResult, OnlinePaymentRefundStatus } from 'typings/Payments';
import {
  AddCustomerToBlacklistDTO,
  GetOsmOrderDetailsRequest,
  OSMOrderDetails,
  OSMOrderCheckoutDetailsDTO,
  OSMOrderList,
  OSMOrderDetailsWithBasketData,
  OSMOrderFiltersDTO,
  OSMOrderAddInternalCommentDTO,
  AssignCustomerCreditDTO,
  OrderRefundEligibility,
} from './allOrders.types';

export const getTableFiltersValues = createAsyncThunk('[ORDERS]/getTableFiltersValues', async () => {
  const result = await posApi.get<OSMOrderFiltersDTO>(posApiUrls.OSM_ORDER_FILTERS);
  return result.data;
});

export const editOrder = createAsyncThunk<boolean, CheckoutDetailsForm, { state: RootState }>(
  '[ALL_ORDERS]/edit',
  async (checkoutData, { getState, rejectWithValue, dispatch }) => {
    const { intake, cashier } = getState();
    const editRequest = {
      customerDetails: {
        emailAddress: checkoutData.emailAddress,
        name: checkoutData.name,
        surname: checkoutData.surname,
        telephoneNumber: checkoutData.telephoneNumber,
        profile: checkoutData.profile,
      },
      requestedCollectionTime: {
        date: checkoutData.date,
        time: formatTimeToOSMDto(checkoutData.time, checkoutData.translations?.asap),
      },
      deliveryAddress: mapToOSMDeliveryAddress(checkoutData),
      pickupType: intake.activeDeliveryType,
      performedByUserId: cashier.loggedCashier?.id as number,
      isEatIn: intake.isEatIn,
    } as OSMOrderCheckoutDetailsDTO;
    try {
      const result = await posApi.put<OSMOrderDetails>(
        posApiUrls.OSM_ORDER_DELIVERY_INFO(intake.editMode?.orderId as string),
        editRequest,
      );
      if (!result || result.status !== 200) throw new Error();
      dispatch(getOrderDetails({ doFetch: true, publicId: intake.editMode?.orderId as string }));
      return true;
    } catch (ex) {
      return rejectWithValue(false);
    }
  },
);

export const getAllOrders = createAsyncThunk<OSMOrderList, OrderFilters, { state: RootState }>(
  '[ALL_ORDERS]/getAllOrdersList',
  async (filters, { getState, rejectWithValue }) => {
    const { allOrders, stores, config } = getState();
    const userStores = stores.availableStores;

    const selectedStoreId = stores.selectedStore?.id?.toString() ?? '';
    const suppliedFilters = {
      ...filters,
      orderStatus: applyOrderStatusFilters(
        allOrders.currentStatusTab,
        filters?.orderStatus ?? [],
        config.fiscalizationConfiguration?.isActive ?? false,
      ),
      storeName:
        allOrders.currentStoreTab === 'my-store'
          ? [selectedStoreId]
          : filters.storeName?.length > 0
          ? filters.storeName
          : userStores?.map((us) => us.id),
    } as OrderFilters;

    const body = mapFiltersToOrdersRequest(allOrders.tableOptions, suppliedFilters);
    try {
      const result = await posApi.put<OSMOrderList>(`${posApiUrls.OSM_HISTORY_ORDERS}`, body);
      if (!result || result.status !== 200) throw new Error();
      return result.data;
    } catch (ex) {
      return rejectWithValue([]);
    }
  },
);

export const downloadOrders = createAsyncThunk<boolean, OrderFilters, { state: RootState }>(
  '[ALL_ORDERS]/downloadOrdersList',
  async (filters, { getState, rejectWithValue }) => {
    const { allOrders, stores, config } = getState();
    const userStores = stores.availableStores;

    const selectedStoreId = stores.selectedStore?.id?.toString() ?? '';
    const suppliedFilters = {
      ...filters,
      orderStatus: applyOrderStatusFilters(
        allOrders.currentStatusTab,
        filters?.orderStatus ?? [],
        config.fiscalizationConfiguration?.isActive ?? false,
      ),
      storeName:
        allOrders.currentStoreTab === 'my-store'
          ? [selectedStoreId]
          : filters.storeName?.length > 0
          ? filters.storeName
          : userStores?.map((us) => us.id),
    } as OrderFilters;
    const body = mapFiltersToOrdersRequest({ ...allOrders.tableOptions, currentPageIndex: 0 }, suppliedFilters);
    try {
      const result = await posApi.put(`${posApiUrls.OSM_HISTORY_ORDERS_DOWNLOAD}`, body, { responseType: `blob` });
      if (!result || result.status !== 200) throw new Error();
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      const match = result.headers[`content-disposition`].match(`filename=([^"]+);`);
      const fileName = match ? match[1] : 'orders.xlsx';
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (ex) {
      return rejectWithValue(false);
    }
  },
);

export const getOrderDetails = createAsyncThunk<
  OSMOrderDetailsWithBasketData,
  GetOsmOrderDetailsRequest,
  { state: RootState }
>('[ALL_ORDERS]/getOrderDetails', async (params, { dispatch, getState }) => {
  const { products, coupons, stores } = getState();
  const result = await posApi.get<OSMOrderDetails>(posApiUrls.OSM_ORDER_DETAILS(params.publicId, params.doFetch));
  const order = result.data;
  const basketItems = order.orderItems
    .map((osmItem) => mapOSMOrderItem(osmItem, products.products, products.options))
    .filter((el) => !!el) as BasketItem[];

  const basketCoupons = await Promise.all(
    order.discounts
      .filter((d) => d.couponId !== undefined)
      .map(async (osmDiscount) => {
        const mappedDiscount = await mapOSMDiscountToBasketCoupon(
          osmDiscount,
          basketItems,
          coupons.couponsMasterData,
          products.options,
          products.products,
          products.categories,
        );
        return mappedDiscount;
      }),
  );
  const isLocalOrder = order.storeId === stores.selectedStore?.id;
  const filteredCoupons = basketCoupons.filter((el) => !!el) as (Coupon | MealCoupon)[];
  const virtualReceipt = generateVirtualReceiptForPaidOrder(order.orderItems, basketItems, order.discounts);
  const historyView = mapOSMHistoryToDayHistory(order.history);

  const giftCardActivationDto = await posApi.get<GiftCardActivationDto>(posApiUrls.GIFT_CARDS_CHECK, {
    params: { orderId: params.publicId, isLocalOrder: false },
  });

  const giftCards = giftCardActivationDto?.data?.giftCards ?? [];
  await dispatch(getPaymentMethods({ pickupType: order.pickupType, storeId: order.storeId }));

  return {
    ...order,
    hasAnyGiftCardsToActivate:
      giftCards.length > 0 && giftCards.some((el) => el.status !== GiftCardActivationStatus.Succeeded),
    isLocalOrder,
    basketItems: basketItems.filter((bi) => bi.isAutomaticItem === false),
    basketCoupons: filteredCoupons,
    virtualReceipt,
    historyView,
  } as OSMOrderDetailsWithBasketData;
});

export const getOrderDetailsForFinalize = createAsyncThunk<
  OSMOrderDetails,
  {
    orderId: string;
    dataCallback: (details: OSMOrderDetails) => void;
  },
  { state: RootState }
>('[ALL_ORDERS]/getOrderDetailsForFinalize', async ({ orderId, dataCallback }, { dispatch }) => {
  const result = await posApi.get<OSMOrderDetails>(posApiUrls.OSM_ORDER_DETAILS(orderId, true));

  await dispatch(getPaymentMethods({ pickupType: result.data.pickupType, storeId: result.data.storeId }));
  if (result.data) {
    dataCallback(result.data);
  }

  return result.data;
});

export const cancelOnPaymentAbandon = createAsyncThunk<
  string,
  {
    orderId: string;
    canceledOrderIdentifier: string;
  },
  { state: RootState }
>('[ALL_ORDERS]/cancelOnPaymentAbandon', async ({ orderId, canceledOrderIdentifier }, { getState }) => {
  const reason = 'Payment started, but abandoned';
  const result = await posApi.put(posApiUrls.ORDER_CANCEL(orderId), { reason });
  if (!isValidSuccessfulAxiosResponse(result)) {
    throw Error(`Could not cancel order ${orderId}`);
  }

  return canceledOrderIdentifier;
});

export const cancelOrder = createAsyncThunk<
  boolean,
  {
    reason: string;
    orderId: string;
    isRefundRequested: boolean;
    successCallback: (isOrderRefunded: boolean) => void;
  },
  { state: RootState }
>(
  '[ALL_ORDERS]/cancelOrder',
  async ({ reason, orderId, isRefundRequested, successCallback }, { getState, rejectWithValue }) => {
    const { cashier } = getState();
    const result = await posApi.put(posApiUrls.OSM_ORDER_CANCEL(orderId, reason, cashier.loggedCashier?.id));

    if (isValidAxiosResponse(result)) {
      if (isRefundRequested) {
        const refundStatusResponse = await posApi.get<GetEftPaymentRefundStatusResult>(
          posApiUrls.PAYMENT_REFUND_STATUS(orderId),
        );
        if (
          isValidAxiosResponse(refundStatusResponse) &&
          refundStatusResponse.data.status === OnlinePaymentRefundStatus.RefundSucceeded
        ) {
          successCallback(true);
          return true;
        }
      }

      successCallback(false);
      return true;
    }

    return rejectWithValue(((result as any)?.response?.data as PosApiErrorResponse)?.title);
  },
);

export const validateUser = createAsyncThunk<
  boolean,
  {
    clockInCallback: () => void;
    successCallback?: () => void;
  },
  { state: RootState }
>('[ALL_ORDERS]/validateUser', async ({ clockInCallback, successCallback }, { rejectWithValue }) => {
  const result = await posApi.get(posApiUrls.OSM_VALIDATE_USER());

  if (isValidAxiosResponse(result)) {
    if (successCallback) successCallback();
    return true;
  }

  if (((result as any)?.response?.data as PosApiErrorResponse)?.errorCode === 'RequireClockIn') {
    clockInCallback();
    return false;
  }

  return rejectWithValue(((result as any)?.response?.data as PosApiErrorResponse)?.title);
});

export const addInternalComment = createAsyncThunk(
  '[ALL_ORDERS]/addInternalComment',
  async ({
    comment,
    orderId,
    successCallback,
  }: {
    comment: string;
    orderId: string;
    successCallback: () => void;
  }) => {
    const request = {
      comment,
    } as OSMOrderAddInternalCommentDTO;

    const result = await posApi.post(posApiUrls.OSM_ORDER_INTERNAL_COMMENTS(orderId), request);

    if (isValidAxiosResponse(result)) {
      successCallback();
    }
  },
);

export const emailReceiptToCustomer = createAsyncThunk(
  '[ALL_ORDERS]/emailReceiptToCustomer',
  async ({ orderId, customerEmail }: { orderId: string; customerEmail: string }) => {
    const response = await fetch('/Online/Order/SendOrderReceiptToCustomer', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        publicId: orderId,
        emailAddress: customerEmail,
      }),
    });

    if (!response.ok) throw new Error('Error during sending receipt to customer');

    const data = await response.json();

    if (!data.succeeded) throw new Error('Error during sending receipt to customer');
  },
);

export const emailReceiptToStore = createAsyncThunk(
  '[ALL_ORDERS]/emailReceiptToStore',
  async ({ orderId }: { orderId: string }) => {
    const response = await fetch('/Online/Order/SendOrderReceiptToStore', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        publicId: orderId,
      }),
    });

    if (!response.ok) throw new Error('Error during sending receipt to store');

    const data = await response.json();

    if (!data.succeeded) throw new Error('Error during sending receipt to store');
  },
);

export const printOrder = createAsyncThunk('[ALL_ORDERS]/printOrder', async ({ orderId }: { orderId: string }) => {
  const result = await posApi.post(posApiUrls.OSM_ORDER_PRINT(orderId));

  if (!isValidAxiosResponse(result)) {
    throw new Error('Error during order print');
  }
});

export const addCustomerToBlacklist = createAsyncThunk(
  '[ALL_ORDERS]/addCustomerToBlacklist',
  async ({
    reason,
    orderId,
    successCallback,
  }: {
    reason: string;
    orderId: string;
    successCallback: () => void;
  }) => {
    const request = {
      orderId,
      reason,
    } as AddCustomerToBlacklistDTO;

    const result = await posApi.post(posApiUrls.CUSTOMER_BLACKLIST, request);

    if (isValidAxiosResponse(result)) {
      successCallback();
    }
  },
);
export const assignCreditToCustomer = createAsyncThunk(
  '[ALL_ORDERS]/addCustomerToBlacklist',
  async ({ amount, reason, orderId }: { amount: number; reason: string; orderId: string }) => {
    const request = {
      orderId,
      reason,
      amount,
    } as AssignCustomerCreditDTO;

    const result = await posApi.post(posApiUrls.CUSTOMER_ADD_CREDIT, request);

    if (!isValidAxiosResponse(result)) {
      throw new Error('Error assigning customer credit');
    }
  },
);

export const finalizePaidOrder = createAsyncThunk(
  '[ALL_ORDERS]/finalizePaidOrder',
  async ({
    orderId,
    successCallback,
    callback,
  }: {
    orderId: string;
    successCallback: () => void;
    callback: () => void;
  }) => {
    const result = await posApi.put(posApiUrls.OSM_ORDER_FINALIZE_PAID(orderId));

    if (isValidAxiosResponse(result)) {
      successCallback();
    }
  },
);

export const checkIfOrderCanBeRefunded = createAsyncThunk<
  OrderRefundEligibility,
  { orderId: string },
  { state: RootState }
>('[OrderPayment]/checkIfOrderCanBeRefunded', async ({ orderId }) => {
  const response = await posApi.get<OrderRefundEligibility>(posApiUrls.ORDER_REFUND_ELIGIBILITY(orderId));

  if (!isValidSuccessfulAxiosResponse(response)) {
    throw new Error(`Order payment refund eligibility could not be get for orderId=${orderId}`);
  }

  return response.data;
});

const applyOrderStatusFilters = (
  currentTab: string,
  baseFilters: string[],
  fiscalizationEnabled: boolean,
): string[] => {
  switch (currentTab) {
    case 'open-orders': {
      return baseFilters?.length > 0 ? baseFilters : OpenOrderStatuses;
    }
    case 'unpaid-orders': {
      return fiscalizationEnabled ? UnpaidOrderStatusesWhenFiscalizationEnabled : UnpaidOrderStatuses;
    }
    default:
      return baseFilters;
  }
};
