import React, { useEffect } from 'react';
import { ButtonBase, Box, useTheme } from '@mui/material';

import { SubOption } from 'typings/Products';
import buildClasses from './ProductSubOptions.css';

interface ProductSubOptionsProps {
  subOptions: SubOption[];
  activeSubOptionId: number | null;
  onSubOptionChange: (optionId: number) => void;
}

const ProductSubOptions: React.FC<ProductSubOptionsProps> = ({
  subOptions,
  activeSubOptionId,
  onSubOptionChange,
}) => {
  const { classes, cx } = buildClasses();
  const { palette, sizing, spacing } = useTheme();

  useEffect(() => {
    if (!activeSubOptionId && subOptions.length > 0) {
      onSubOptionChange(subOptions[0].id);
    }
  });

  return (
    <Box
      sx={{
        backgroundColor: `${palette.secondary.light}`,
        display: 'flex',
        flexDirection: 'row',
        width: sizing.full,
        borderRadius: spacing(0.5),
        overflowX: 'auto',
      }}
      data-testid="product-selection__container--sub-options"
    >
      {subOptions &&
        subOptions.map(
          (subOption) =>
            subOption.description && (
              <ButtonBase
                className={cx(classes.buttonBox, {
                  [classes.active]: activeSubOptionId === subOption.id,
                })}
                onClick={(): void => {
                  onSubOptionChange(subOption.id);
                }}
                key={`subOption_${subOption.id}`}
                data-testid={`product-sub-options__button--${subOption.description}`}
              >
                {subOption.description.toUpperCase()}
              </ButtonBase>
            ),
        )}
    </Box>
  );
};

export default ProductSubOptions;
