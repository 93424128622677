import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { ProductDescription } from 'stores/AllOrders';
import { VatSummary } from 'typings/Basket';
import { PaymentMethodCode } from 'typings/Payments';

export interface LocalOrdersState {
  ordersFetchInProgress: boolean;
  ordersList: LocalOrder[];
  reloadOrdersTrigger?: string;
}

export enum SynchronizationStatuses {
  'Pending' = 'Pending',
  'Success' = 'Success',
  'TryAgainLater' = 'TryAgainLater',
}

export enum PlacementMode {
  'Unresolved' = 'Unresolved',
  'Offline' = 'Offline',
  'Direct' = 'Direct',
}

export interface LocalOrder {
  canCancel: boolean;
  orderId: string;
  ticketNumber: number;
  customerId?: number;
  customerName: string;
  address: string;
  phoneNumber: string;
  price: number;
  orderPlacedTimestamp: string;
  collectionTimestamp: string;
  pickupType: PickUpTypesValues;
  orderStatus: string;
  orderSynchronizationStatus: SynchronizationStatuses;
  placementMode: PlacementMode;
  waitTime: string;
  shortProductDescription: ProductDescription[];
  paymentMethodCode: PaymentMethodCode;
}

export interface PriceSummary {
  originalTotal: number;
  total: number;
  netTotal: number;
  discount: number;
  vatBreakdown: VatSummary[];
  totalVat: number;
  subtotal: number;
}

export interface GetLocalOrdersRequest {
  PickupType?: string;
  OrderStatus?: string;
  PaymentMethodCode?: string;
  DateFrom?: string;
  DateTo?: string;
  TicketNumber?: number;
  Search?: string;
}
