import { ToppingsCustomizationConfiguration } from 'stores/Config/configTypes';
import { BasketItem } from 'typings/Basket';
import {
  Product,
  Customization,
  CustomizationProduct,
  OptionPrice,
  Topping,
  ToppingsCounter,
  Option,
  ProductTopping,
  ToppingDisplayModel,
  SliceCustomization,
  ToppingSelectionSimplified,
} from 'typings/Products';
import { skipFromToppingWeightCalculationCategories } from 'containers/Intake/Products/ProductCustomization/CustomizeProduct/CustomizeProduct';
import { isProductSetComplete } from './productSetUtils';

export function getProductCustomization(
  baseProduct: Product,
  selToppings?: ToppingSelectionSimplified[],
): Customization {
  return {
    sliceNumber: 0,
    selectedPizza: baseProduct,
    selectedToppings: selToppings ?? [],
  } as Customization;
}

export function isItemRemovedFromBasket(basket: BasketItem[], product: CustomizationProduct): boolean {
  return (
    product.basketItemGuid !== undefined && !basket.some((el) => el.basketItemGuid === product.basketItemGuid)
  );
}

export function filterOptionPricesByBaseProduct(baseProduct: Product, options: Option[]): OptionPrice[] {
  const temp: OptionPrice[] = [];
  baseProduct &&
    baseProduct.options.forEach((op) => {
      const allowedOptionIndex = options.findIndex((el) => el.id === op.productOptionId);
      if (allowedOptionIndex !== -1) {
        temp.push({ ...options[allowedOptionIndex], productPrice: op.basePrice });
      }
    });
  return temp.sort((a, b) => a.sortOrder - b.sortOrder); // sort by sortOrder asc
}

export function getAvailableProductOptions(
  productToCustomize: CustomizationProduct,
  options: Option[],
): OptionPrice[] {
  const optionsAfterRestriction =
    productToCustomize.restrictions?.allowedOptionsIds === undefined
      ? options
      : options.filter(
          (op) =>
            !productToCustomize.restrictions || productToCustomize.restrictions.allowedOptionsIds?.includes(op.id),
        );

  return filterOptionPricesByBaseProduct(productToCustomize.baseProduct, optionsAfterRestriction);
}

export function isCustomizableProductComplete(product?: CustomizationProduct): boolean {
  if (!product) return false;

  if (product.baseProduct.isXTasty) {
    return isXTastyComplete(product);
  }

  if (product.baseProduct.hasToppingsSelectionSteps) {
    return isProductSetComplete(product);
  }

  return true;
}

export function prepareToppingsCounter(
  product: Product,
  selectedToppings: ToppingSelectionSimplified[],
  toppingsDefinitions: Topping[],
  toppingsCustomizationConfiguration: ToppingsCustomizationConfiguration,
): ToppingsCounter {
  if (!toppingsCustomizationConfiguration) {
    throw new Error('ToppingsEditionConfiguration is missing');
  }

  const counter = {
    enabled: true,
    maxNumberOfToppings: 0,
  } as ToppingsCounter;

  if (product.isCYO) {
    counter.maxNumberOfToppings = toppingsCustomizationConfiguration.maximumTotalToppingsForCyo;
  } else {
    const ignoredToppingIds =
      selectedToppings
        ?.filter((el) =>
          skipFromToppingWeightCalculationCategories.some((ctg) => ctg === el.toppingCategoryCode.toUpperCase()),
        )
        .map((x) => x.id) ?? [];

    const defaultToppingsIds = product.defaultToppings.map((t) => t.toppingId);
    const defaultToppingsDefinitions = toppingsDefinitions.filter((t) => defaultToppingsIds.includes(t.id));
    const defaultNumberOfToppings = product.defaultToppings
      .filter((t) => !ignoredToppingIds.find((sr) => sr === t.toppingId))
      .reduce((accumulator, object) => {
        return (
          accumulator +
          (object.quantity ?? 0) *
            (defaultToppingsDefinitions.find((td) => td.id === object.toppingId)?.calculationWeight ?? 1)
        );
      }, 0);

    counter.maxNumberOfToppings =
      defaultNumberOfToppings + toppingsCustomizationConfiguration.maximumExtraToppings;
  }

  return counter;
}

function isXTastyComplete(product: CustomizationProduct): boolean {
  if (!product.sliceCustomizations) {
    return false;
  }

  if (!product.baseProduct.xTastySettings) {
    throw new Error(`Missing XTastySettings for product: ${product.baseProduct.id}`);
  }

  return product.sliceCustomizations.length === product.baseProduct.xTastySettings.numberOfSlices;
}

export function generateToppingConfiguration(
  product?: Product,
  addedToppings?: ToppingDisplayModel[],
  removedToppings?: ToppingDisplayModel[],
): ProductTopping[] {
  const toppingConfiguration: ProductTopping[] = product ? [...product.defaultToppings] : [];

  removedToppings?.forEach((rt) => {
    const defaultToppingIndex = toppingConfiguration.findIndex((el) => el.toppingId === rt.toppingId);
    if (defaultToppingIndex < 0) return;

    const defaultTopping = toppingConfiguration[defaultToppingIndex];

    if (defaultTopping.quantity > rt.quantity) {
      const newQuantity = toppingConfiguration[defaultToppingIndex].quantity - rt.quantity;

      toppingConfiguration[defaultToppingIndex] = {
        ...toppingConfiguration[defaultToppingIndex],
        quantity: newQuantity,
      };
    }
    if (defaultTopping.quantity === rt.quantity) {
      toppingConfiguration.splice(defaultToppingIndex, 1);
    }
  });
  addedToppings?.forEach((at) => {
    const defaultToppingIndex = toppingConfiguration.findIndex((el) => el.toppingId === at.toppingId);
    if (defaultToppingIndex >= 0) {
      const newQuantity = toppingConfiguration[defaultToppingIndex].quantity + at.quantity;

      toppingConfiguration[defaultToppingIndex] = {
        ...toppingConfiguration[defaultToppingIndex],
        quantity: newQuantity,
      };
    } else {
      toppingConfiguration.push({
        toppingId: at.toppingId,
        quantity: at.quantity,
      });
    }
  });

  return toppingConfiguration;
}

export function getDefaultSliceCustomizations(product?: CustomizationProduct): SliceCustomization[] | undefined {
  if (!product) return undefined;
  const { baseProduct } = product;
  if (!baseProduct || baseProduct.isXTasty) return undefined;
  return [
    {
      sliceProductId: baseProduct.id,
      sliceProductName: baseProduct.name,
      toppingsConfiguration: baseProduct.defaultToppings,
      addedToppings: [],
      removedToppings: [],
    },
  ];
}
