import { compareAlphabetically } from 'stores/Intake/IntakeStoreUtils';
import {
  Topping,
  ToppingSelection,
  ProductTopping,
  Product,
  SubOption,
  Customization,
  SliceCustomization,
  ToppingDisplayModel,
  Option,
  ToppingSelectionSimplified,
} from 'typings/Products';

export function mapToppingToToppingSelection(allToppings: Topping[]): ToppingSelection[] {
  return allToppings.map((topping) => ({ ...topping, quantity: 0 }));
}

export function removeForbiddenToppings(
  allToppings: ToppingSelection[],
  forbiddenToppings?: ProductTopping[],
): ToppingSelection[] {
  return allToppings.filter(
    (topping) =>
      forbiddenToppings?.findIndex((forbiddenTopping) => forbiddenTopping.toppingId === topping.id) === -1,
  );
}

export function mapToSimplifiedToppingSelection(
  availableProductToppings: Topping[],
  defaultToppings?: ProductTopping[],
): ToppingSelectionSimplified[] {
  return (
    defaultToppings?.map((dt) => {
      const baseTopping = availableProductToppings.find((el) => el.id === dt.toppingId);
      return {
        name: baseTopping?.name as string,
        id: dt.toppingId,
        calculationWeight: baseTopping?.calculationWeight as number,
        quantity: dt.quantity,
        toppingCategoryCode: baseTopping?.toppingCategoryCode as string,
      };
    }) ?? []
  );
}

export function setDefaultSauce(allSauces: ToppingSelection[]): ToppingSelection[] {
  const temp = [...allSauces];
  const selectedSauceIndex = temp.findIndex((el) => el.quantity === 1);
  if (selectedSauceIndex !== -1) {
    return allSauces;
  }
  if (temp.length > 0) {
    temp[0].quantity = 1;
  }
  return temp;
}

export function removeXTastyPizzas(allPizzas: Product[]): Product[] {
  return allPizzas.filter((pizza) => pizza.isXTasty !== true);
}

export function filterAllowedXTastyProducts(baseProduct: Product, allPizzas: Product[]): Product[] {
  const allowedPizzaIds = baseProduct?.allowedXTastyProducts?.map((product) => product.productId) ?? [];

  const xTastyAllowedPizzas = allPizzas?.filter((pizza) => allowedPizzaIds.includes(pizza.id)) ?? [];

  return xTastyAllowedPizzas;
}

export function filterOptionsByBaseProduct(baseProduct: Product, options: Option[]): Option[] {
  const temp: Option[] = [];
  baseProduct &&
    baseProduct.options.forEach((op) => {
      const allowedOptionIndex = options.findIndex((el) => el.id === op.productOptionId);
      if (allowedOptionIndex !== -1) {
        temp.push(options[allowedOptionIndex]);
      }
    });
  return temp.sort((a, b) => a.id - b.id); // sort by id asc
}

export function removeSubOptionWithoutOptions(options: Option[], subOptions: SubOption[]): SubOption[] {
  const temp: SubOption[] = [];
  options.forEach((op) => {
    const allowedSubOptionIndex = subOptions.findIndex((el) => el.id === op.optionSubTypeId);
    if (allowedSubOptionIndex !== -1) {
      temp.push(subOptions[allowedSubOptionIndex]);
    }
  });

  return Array.from(new Set(temp)).sort((a, b) => {
    const sortOrderA = options.find((option) => option.optionSubTypeId === a.id)?.sortOrder || 0;
    const sortOrderB = options.find((option) => option.optionSubTypeId === b.id)?.sortOrder || 0;
    return sortOrderA - sortOrderB;
  }); // return only uniq subOptions sorted by id asc
}

export function sortToppingsAlphabetically(toppings: ToppingSelection[]): ToppingSelection[] {
  return toppings.sort((a, b) => compareAlphabetically(a.name, b.name));
}

export function createSliceCustomizations(
  pizzaSlices: Customization[],
  defaultToppings: ProductTopping[] = [],
): SliceCustomization[] {
  const slices: SliceCustomization[] = [];
  pizzaSlices.forEach((pizzaSlice) => {
    if (pizzaSlice.selectedPizza?.id && pizzaSlice.selectedToppings) {
      const allAppliedToppings = pizzaSlice.selectedToppings.filter((tp) => tp.quantity !== 0);
      const selectedToppingsConfiguration = allAppliedToppings.map((topping) => ({
        toppingId: topping.id,
        quantity: topping.quantity ?? 0,
      }));
      let compressedToppings = compressToppings(selectedToppingsConfiguration);

      if (pizzaSlice.selectedPizza.hasToppingsSelectionSteps) {
        const allStepsAvailableToppings = pizzaSlice.selectedPizza.toppingSelectionSteps?.flatMap(
          (tss) => tss.availableToppings,
        );
        const defaultToppingsToConcat = defaultToppings.filter((defaultTopping) => {
          const wasToppingAvailable = allStepsAvailableToppings?.some(
            (asat) => asat.toppingId === defaultTopping.toppingId,
          );
          if (wasToppingAvailable) return false;
          return true;
        });
        compressedToppings = compressToppings(compressedToppings.concat(defaultToppingsToConcat));
      }

      slices.push({
        sliceProductId: pizzaSlice.selectedPizza?.id,
        sliceProductName: pizzaSlice.selectedPizza?.name,
        toppingsConfiguration: compressedToppings,
      });
    }
  });
  return slices;
}

export function findAddedAndRemovedToppings(
  defaultToppings: ProductTopping[],
  appliedToppings: ToppingSelectionSimplified[],
): { addedToppings: ToppingDisplayModel[]; removedToppings: ToppingDisplayModel[] } {
  const addedToppings: ToppingDisplayModel[] = [];
  const removedToppings: ToppingDisplayModel[] = [];
  appliedToppings.forEach((appliedTopping) => {
    const defaultTopping = defaultToppings.find((el) => el.toppingId === appliedTopping.id);
    if (defaultTopping) {
      if (appliedTopping.quantity === 2) {
        // added one more
        addedToppings.push({
          toppingId: appliedTopping.id,
          toppingName: appliedTopping.name,
          quantity: appliedTopping.quantity,
        });
      }
      if (!appliedTopping.quantity || appliedTopping.quantity === 0) {
        removedToppings.push({ toppingName: appliedTopping.name, toppingId: appliedTopping.id, quantity: 1 });
      }
    } else {
      (appliedTopping.quantity as number) > 0 &&
        addedToppings.push({
          toppingId: appliedTopping.id,
          toppingName: appliedTopping.name,
          quantity: appliedTopping.quantity as number,
        });
    }
  });
  return { addedToppings, removedToppings };
}

export function createSliceCustomizationsWithDisplayModel(
  pizzaSlices: Customization[],
  defaultToppings: ProductTopping[] = [],
): SliceCustomization[] {
  const slices: SliceCustomization[] = [];
  pizzaSlices.forEach((pizzaSlice) => {
    if (pizzaSlice.selectedPizza?.id && pizzaSlice.selectedToppings) {
      const { addedToppings, removedToppings } = findAddedAndRemovedToppings(
        pizzaSlice.selectedPizza.defaultToppings,
        pizzaSlice.selectedToppings,
      );
      const selectedToppingsConfiguration = pizzaSlice.selectedToppings
        .filter((tp) => tp.quantity !== 0)
        .map((topping) => ({ toppingId: topping.id, quantity: topping.quantity ?? 0 }));

      let compressedToppings = compressToppings(selectedToppingsConfiguration);

      if (pizzaSlice.selectedPizza.hasToppingsSelectionSteps) {
        const allStepsAvailableToppings = pizzaSlice.selectedPizza.toppingSelectionSteps?.flatMap(
          (tss) => tss.availableToppings,
        );
        const defaultToppingsToConcat = defaultToppings.filter((defaultTopping) => {
          const wasToppingAvailable = allStepsAvailableToppings?.some(
            (asat) => asat.toppingId === defaultTopping.toppingId,
          );
          if (wasToppingAvailable) return false;
          return true;
        });
        compressedToppings = compressToppings(compressedToppings.concat(defaultToppingsToConcat));
      }

      slices.push({
        sliceProductId: pizzaSlice.selectedPizza?.id,
        sliceProductName: pizzaSlice.selectedPizza?.name,
        removedToppings,
        addedToppings,
        toppingsConfiguration: compressedToppings,
      });
    }
  });
  return slices;
}

function compressToppings(toppings: ProductTopping[]) {
  const result: ProductTopping[] = [];
  for (let i = 0; i < toppings.length; i += 1) {
    const idx = result.findIndex((x) => x.toppingId === toppings[i].toppingId);
    if (idx < 0) result.push(toppings[i]);
    else result[idx].quantity += toppings[i].quantity;
  }
  return result;
}

export function createEmptyModelForXSlices(slicesCount: number): Customization[] {
  const XTastyEmptyModel: Customization[] = [];
  for (let i = 0; i < slicesCount; i += 1) {
    XTastyEmptyModel.push({
      sliceNumber: i,
    });
  }
  return XTastyEmptyModel;
}

export function mapSliceCustomizationsToXTastyModel(
  slices: SliceCustomization[],
  products: Product[],
  toppings: Topping[],
): Customization[] {
  const XTastyModel: Customization[] = [];
  slices.forEach((slice, index) => {
    const pizza = products.find((prod) => prod.id === slice.sliceProductId);
    if (pizza !== undefined) {
      const productAllowedToppings = removeForbiddenToppings(toppings, pizza.forbiddenToppings);

      const XTastyModelPart: Customization = {
        sliceNumber: index,
        selectedPizza: pizza,
        selectedToppings: slice.toppingsConfiguration.map((tc) => {
          const baseTopping = productAllowedToppings.find((el) => el.id === tc.toppingId);
          return { ...(baseTopping as ToppingSelection), quantity: tc.quantity };
        }),
      };
      XTastyModel.push(XTastyModelPart);
    }
  });

  return XTastyModel;
}
