import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { isValidAxiosResponse } from 'typings/type-guards';
import { GetLocalOrdersRequest, LocalOrder } from './localOrdersTypes';

export const getLocalOrdersList = createAsyncThunk(
  '[ORDERS]/getLocalOrdersList',
  async (params?: GetLocalOrdersRequest) => {
    const result = await posApi.get<LocalOrder[]>(posApiUrls.HISTORY_ORDERS, { params });
    if (result.status !== 200) throw new Error('Error getting local orders');
    return result.data;
  },
);

export const postReprintOrder = createAsyncThunk('[ORDERS]/postReprintOrder', async (params: string) => {
  const result = await posApi.post(posApiUrls.HISTORY_ORDER_REPRINT(params));
  return result.data;
});

export const cancelLocalOrder = createAsyncThunk(
  '[INTAKE]/cancelOrder',
  async (
    {
      reason,
      orderId,
      successCallback,
    }: {
      reason: string;
      orderId: string;
      successCallback: () => void;
    },
    { dispatch },
  ) => {
    // dispatch(clearBasket()); // seems to not be needed

    const result = await posApi.put(posApiUrls.ORDER_CANCEL(orderId), { reason });

    if (isValidAxiosResponse(result)) {
      successCallback();
    }
  },
);
