import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { getAppInsights } from 'App/AppInitializationWrapper/AppInitializationWrapper';
import { AxiosError } from 'axios';
import { RootState } from 'stores';
import { checkIfOrderIsFullyPaid } from 'stores/OrderPayment/orderPayment.actions';
import { setActivePaymentCustomerPaysWith } from 'stores/OrderPayment/orderPayment.slice';
import {
  EftCancelationResult,
  EftPaymentStatus,
  EftStatusResponse,
  ExternalCallResult,
} from 'typings/EftPayment/eftPayment.types';
import { EftError } from 'typings/Payments';
import { isValidSuccessfulAxiosResponse } from 'typings/type-guards';
import { getFinalizationTimeData } from 'stores/OrderPayment/orderPayment.selector';
import { automaticFinalization } from './eftPayment.slice';

export const startEftPayment = createAsyncThunk<
  string,
  { orderAmount: number; paymentId: string; orderId: string; tipAmount?: number; giftCardNumber?: string },
  { state: RootState; rejectValue?: string }
>(
  '[EFT-Payment]/startEftPayment',
  async (
    { orderAmount, paymentId, orderId, tipAmount, giftCardNumber },
    { dispatch, getState, rejectWithValue },
  ) => {
    const { selectedStore } = getState().stores;
    const { isLocalOrder, payments } = getState().orderPayment;
    const activePaymentMethod = payments.find((el) => el.isActive);
    const appInsights = getAppInsights();

    const request = {
      orderAmount,
      orderId,
      paymentMethodId: activePaymentMethod?.paymentMethod?.id,
      isLocalOrder,
      tipAmount,
      giftCardNumber,
    };
    appInsights.trackEvent({
      name: 'Eft payment start requested',
      properties: {
        request,
      },
    });

    if (!selectedStore) {
      return rejectWithValue('Cannot start EFT without store selected');
    }

    const result = await posApi
      .put<EftStatusResponse>(`${posApiUrls.EFT_PAYMENT_V2}/${paymentId}`, JSON.stringify(request))
      .catch((err: AxiosError<{ errorCode: string }>) => err);

    if (isValidSuccessfulAxiosResponse(result)) {
      return paymentId;
    }

    return rejectWithValue(result?.response?.data?.errorCode);
  },
);

export const retryEftPayment = createAsyncThunk<void, void, { state: RootState }>(
  '[EFT-Payment]/retryEftPayment',
  async (_, { getState }) => {
    const { eftPaymentId } = getState().eftPayment;
    const appInsights = getAppInsights();
    appInsights.trackEvent({
      name: 'Eft payment retrying requested',
      properties: { Identifier: eftPaymentId },
    });

    if (!eftPaymentId) {
      throw Error('Cannot retry eft payment without eft payment id in context');
    }
    const result = await posApi
      .put(posApiUrls.EFT_PAYMENT_V2_RETRY(eftPaymentId))
      .catch((err: AxiosError<{ title: string }>) => err);

    if (result.status !== 200) {
      throw Error('Error retrying eft payment');
    }
  },
);

export const getEftPaymentStatus = createAsyncThunk<
  EftStatusResponse,
  { paymentId: string },
  { state: RootState }
>('[EFT-payment]/getEftPaymentStatus', async ({ paymentId }, { dispatch, rejectWithValue, getState }) => {
  const response = await posApi.get<EftStatusResponse>(`${posApiUrls.EFT_PAYMENT_V2}/${paymentId}`);

  if (response.status === 200) {
    const appInsights = getAppInsights();
    const { eftAcceptanceInProgress } = getState().eftPayment;
    const { orderPayment } = getState();
    const { config } = getState();

    appInsights.trackEvent({
      name: 'Eft payment V2 status update',
      properties: { Identifier: paymentId, Status: response.data.status },
    });

    if (
      response.data.status === EftPaymentStatus.Successful &&
      getFinalizationTimeData(orderPayment, config).skipFinalizationScreen &&
      !eftAcceptanceInProgress
    ) {
      dispatch(automaticFinalization());
      dispatch(acceptEftPayment());
    }

    if (response.data.status === EftPaymentStatus.Accepted) {
      dispatch(checkIfOrderIsFullyPaid());
    }

    if (response.data.status === EftPaymentStatus.Successful && response.data.authorizedAmount) {
      dispatch(setActivePaymentCustomerPaysWith(response.data.authorizedAmount));
    }

    return response.data;
  }

  return rejectWithValue(
    response instanceof AxiosError ? response.response?.data.title : EftError.EftResultUnknown,
  );
});

export const acceptEftPayment = createAsyncThunk<string, void, { state: RootState }>(
  '[EFT-payment]/acceptEftPayment',
  async (_, { getState }) => {
    const { eftPaymentId } = getState().eftPayment;
    const { isLocalOrder, orderId } = getState().orderPayment;
    if (!eftPaymentId) throw new Error('No Eft operation in progress');
    if (!orderId) throw new Error('Cannot accept payment without order in context');

    const appInsights = getAppInsights();
    appInsights.trackEvent({
      name: 'Eft payment acceptation requested',
      properties: { Identifier: eftPaymentId },
    });

    const response = await posApi.put(posApiUrls.EFT_PAYMENT_V2_ACCEPT(eftPaymentId), {
      isLocalOrder,
    });

    if (response.status !== 200) {
      throw Error('Payment cannot be accepted, there is an api error');
    }
    return orderId;
  },
);

export const cancelEftPayment = createAsyncThunk<EftCancelationResult, void, { state: RootState }>(
  '[EFT-payment]/cancelEftPayment',
  async (_, { getState }) => {
    const { eftPaymentId } = getState().eftPayment;
    if (!eftPaymentId) throw new Error('No Eft operation in progress');

    const appInsights = getAppInsights();
    appInsights.trackEvent({
      name: 'Eft payment cancellation requested',
      properties: { Identifier: eftPaymentId },
    });

    const response = await posApi.put<EftCancelationResult>(posApiUrls.EFT_PAYMENT_V2_CANCEL(eftPaymentId));

    if (response.status !== 200 || response.data.externalCallResult !== ExternalCallResult.Success) {
      throw Error('Payment cannot be canceled, there is an api error');
    }
    return response.data;
  },
);

export const abandonEftPayment = createAsyncThunk<void, void, { state: RootState }>(
  '[EFT-payment]/abandonEftPayment',
  async (_, { getState }) => {
    const { eftPaymentId } = getState().eftPayment;
    if (!eftPaymentId) throw new Error('No Eft operation in progress');

    const appInsights = getAppInsights();
    appInsights.trackEvent({
      name: 'Eft payment abandon requested',
      properties: { Identifier: eftPaymentId },
    });

    const response = await posApi.put<EftCancelationResult>(posApiUrls.EFT_PAYMENT_V2_ABANDON(eftPaymentId));

    if (response.status !== 200) {
      throw Error('Payment cannot be abandoned, there is an api error');
    }
  },
);

export const confirmEftManually = createAsyncThunk<void, void, { state: RootState }>(
  '[EFT-payment]/confirmEftManually',
  async (_, { getState }) => {
    const { eftPaymentId } = getState().eftPayment;
    const { isLocalOrder } = getState().orderPayment;
    if (!eftPaymentId) throw new Error('No Eft operation in progress');

    const appInsights = getAppInsights();
    appInsights.trackEvent({
      name: 'Eft payment manual confirmation requested',
      properties: { Identifier: eftPaymentId },
    });

    const response = await posApi.put(posApiUrls.EFT_PAYMENT_V2_MANUAL_CONFIRM(eftPaymentId), {
      isLocalOrder,
    });

    if (response.status !== 200) {
      throw Error('Payment cannot be manually confirmed, there is an api error');
    }
  },
);

/* DEVELOPMENT-ACTIONS */

export enum ProviderPaymentStatus {
  Registered,
  Started,
  Succeeded,
  Canceled,
  Failed,
  FailedToStart,
}

export enum PaymentProviderEventType {
  PaymentSucceeded = 'PaymentSucceeded',
  PaymentFailed = 'PaymentFailed',
  PaymentCanceled = 'PaymentCanceled',
}

export enum StartPaymentError {
  ProviderUnreachable,
  ProviderConnectionInterrupted,
  InvalidDeviceId,
  EftDeviceNotReady,
  EftDeviceBusy,
  EftDeviceUnreachable,
}

export enum CancelPaymentError {
  ProviderUnreachable,
  ProviderConnectionInterrupted,
  InvalidDeviceId,
  UnknownPayment,
  CannotCancelPayment,
}

export const pushNextStartPaymentResult = createAsyncThunk<
  void,
  { callResult: ExternalCallResult; error?: StartPaymentError },
  { state: RootState }
>('[EFT-DEV]/pushNextCancelationResult', async ({ callResult, error }, { getState }) => {
  const { eftPaymentId } = getState().eftPayment;
  if (!eftPaymentId) throw new Error('No Eft operation in progress');
  await posApi.put(posApiUrls.PAYMENT_V2_DEV_PAYMENT_START, {
    callResult,
    error,
  });
});

export const pushNextCancelationResult = createAsyncThunk<
  void,
  { callResult: ExternalCallResult; errorCode?: CancelPaymentError },
  { state: RootState }
>('[EFT-DEV]/pushNextCancelationResult', async ({ callResult, errorCode }, { getState }) => {
  const { eftPaymentId } = getState().eftPayment;
  if (!eftPaymentId) throw new Error('No Eft operation in progress');
  await posApi.put(posApiUrls.PAYMENT_V2_DEV_PAYMENT_CANCEL, {
    callResult,
    errorCode,
  });
});

export const setNextEftStatusResponse = createAsyncThunk<
  void,
  { callResult: ExternalCallResult; status: ProviderPaymentStatus },
  { state: RootState }
>('[EFT-DEV]/setNextEftStatusResponse', async ({ callResult, status }, { getState }) => {
  const { eftPaymentId } = getState().eftPayment;
  if (!eftPaymentId) throw new Error('No Eft operation in progress');

  await posApi.put(posApiUrls.PAYMENT_V2_DEV_PAYMENT_STATUS, {
    callResult,
    status,
  });
});

export const pushCurrentPaymentProviderEvent = createAsyncThunk<
  void,
  { eventType: PaymentProviderEventType },
  { state: RootState }
>('[EFT-DEV]/pushCurrentPaymentProviderEvent', async ({ eventType }, { getState }) => {
  const { eftPaymentId } = getState().eftPayment;
  if (!eftPaymentId) throw new Error('No Eft operation in progress');
  await posApi.post(posApiUrls.PAYMENT_V2_DEV_PAYMENT_EVENT, ['string'], {
    params: {
      paymentId: eftPaymentId,
      type: eventType,
    },
  });
});
