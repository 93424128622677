import { CSSObject } from '@mui/material/styles';

import { BASE_THEME } from './base.theme.css';

const NYP_COLORS = {
  ...BASE_THEME.palette,
  primary: {
    main: '#0f9b49',
    light: '#4CAF50',
    dark: '#0d8640',
    contrastText: '#fff',
  },
  secondary: {
    ...BASE_THEME.palette.secondary,
    main: '#ededed',
  },
  error: {
    ...BASE_THEME.palette.error,
    main: '#D9534F',
    dark: '#6f0094',
    light: '#df80ff',
  },
  success: {
    light: '#81c784',
    main: '#4caf50',
    dark: '#388e3c',
    contrastText: '#fff',
  },
};

export const NYP = {
  ...BASE_THEME,
  palette: {
    ...NYP_COLORS,
    virtualReceipt: {
      items: {
        discount: {
          active: {
            icon: 'rgb(255,140,0)',
            background: 'rgb(255,248,180)',
          },
          inactive: {
            icon: BASE_THEME.palette.text.disabled,
            background: 'rgb(255,180,180)',
          },
          error: {
            icon: 'rgb(255,41,41)',
            background: NYP_COLORS.error.light,
          },
        },
      },
    },
  },
  components: {
    ...BASE_THEME.components,
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: `#0d8640`,
        },
        arrow: {
          color: `#0d8640`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        colorSecondary: {
          color: 'white',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ...(BASE_THEME.components?.MuiCssBaseline?.styleOverrides as CSSObject),
        '::-webkit-scrollbar-thumb': {
          background: '#0d8640',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: '#0d8640',
        },
      },
    },
  },
};
