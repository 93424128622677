import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConnectivityStatusEnum } from 'services/ConnectivityStatusService';
import uuidGenerator from 'utils/GuidGenerator';
import { MachineType } from 'typings/Auth';
import {
  loadAllConfig,
  loadFeatureFlags,
  getFirstTimeLaunchInfo,
  getAppVersion,
  getVersionUpgradeStatus,
  getSyncStatus,
  getSyncHistory,
} from './config.thunk-actions';
import { ConfigState, InstanceType } from './configTypes';

export const configInitialState: ConfigState = {
  configLoaded: false,
  featureFlags: {},
  themeKey: 'S4D',
  connectivityStatus: ConnectivityStatusEnum.Disconnected,
  showFirstTimeLaunch: false,
  maxUserIdleTime: '00:01:00',
  eftPaymentSettings: {
    statusPollingIntervalInSeconds: 1,
    manualFinishEnabledDelayInSeconds: 5,
    additionalMethodsWhenEftIsForced: [],
    hideManualFinishButton: false,
  },
  appVersion: {
    baseVersion: '',
  },
  syncHistory: [],
  defaultStoreOnSiteMachineType: MachineType.Registry,
  maxPriceOverride: 1000,
  maxDeliveryChargeOverride: 1000,
  maxCustomerCreditAssignment: 1000,
};

const configSlice = createSlice({
  name: '[CONFIG]',
  initialState: configInitialState,
  reducers: {
    updateThemeKey(state, action: PayloadAction<string>) {
      return { ...state, themeKey: action.payload };
    },
    setFeatureFlag(state, action: PayloadAction<{ key: string; value: boolean }>) {
      const { key, value } = action.payload;
      const featureFlagsChanged = { ...state.featureFlags };
      featureFlagsChanged[key] = value;
      return { ...state, featureFlags: featureFlagsChanged };
    },
    setInstanceType(state, action: PayloadAction<InstanceType>) {
      return { ...state, instanceType: action.payload };
    },
    setIdleTime(state, action: PayloadAction<string>) {
      return { ...state, maxUserIdleTime: action.payload };
    },
    updateConnectivityStatus(state, action: PayloadAction<ConnectivityStatusEnum>) {
      return { ...state, connectivityStatus: action.payload };
    },
    resetLoggingCorrelationId(state) {
      return {
        ...state,
        loggingCorrelationId: uuidGenerator(),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadAllConfig.fulfilled, (state, action) => {
      return { ...state, ...action.payload, configLoaded: true };
    });
    builder.addCase(loadFeatureFlags.fulfilled, (state, action) => {
      return {
        ...state,
        featureFlags: { ...action.payload },
      };
    });
    builder.addCase(getFirstTimeLaunchInfo.fulfilled, (state, action) => ({
      ...state,
      showFirstTimeLaunch: action.payload,
    }));
    builder.addCase(getAppVersion.fulfilled, (state, action) => ({
      ...state,
      appVersion: action.payload,
    }));
    builder.addCase(getVersionUpgradeStatus.fulfilled, (state, action) => ({
      ...state,
      appConfigStatus: action.payload,
    }));
    builder.addCase(getSyncStatus.fulfilled, (state, action) => ({
      ...state,
      syncStatus: action.payload,
    }));
    builder.addCase(getSyncHistory.fulfilled, (state, action) => ({
      ...state,
      syncHistory: action.payload,
    }));
  },
});

const { reducer } = configSlice;
export const {
  updateConnectivityStatus,
  resetLoggingCorrelationId,
  updateThemeKey,
  setFeatureFlag,
  setInstanceType,
  setIdleTime,
} = configSlice.actions;

export default reducer;
