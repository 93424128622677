import { createSlice } from '@reduxjs/toolkit';
import { finalizeOrder } from 'stores/Intake';
import uuidGenerator from 'utils/GuidGenerator';
import { cancelLocalOrder, getLocalOrdersList } from './localOrders.thunk-actions';
import { LocalOrdersState } from './localOrdersTypes';

const initialState: LocalOrdersState = {
  ordersFetchInProgress: false,
  ordersList: [],
};

const localOrdersSlice = createSlice({
  name: '[LOCAL-ORDERS]',
  initialState,
  reducers: {
    restartLocalOrdersState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getLocalOrdersList.pending, (state) => ({
      ...state,
      ordersFetchInProgress: true,
    }));
    builder.addCase(getLocalOrdersList.fulfilled, (state, action) => ({
      ...state,
      ordersList: action.payload,
      ordersFetchInProgress: false,
    }));
    builder.addCase(getLocalOrdersList.rejected, (state) => ({
      ...state,
      ordersFetchInProgress: false,
    }));
    builder.addCase(finalizeOrder.fulfilled, (state) => ({
      ...state,
      reloadOrdersTrigger: uuidGenerator(),
    }));
    builder.addCase(cancelLocalOrder.fulfilled, (state) => ({
      ...state,
      reloadOrdersTrigger: uuidGenerator(),
    }));
  },
});

const { reducer } = localOrdersSlice;

export const { restartLocalOrdersState } = localOrdersSlice.actions;

export default reducer;
