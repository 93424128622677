import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { manualExitOrderPayment } from 'stores/OrderPayment/orderPayment.actions';
import {
  AuthorizationDto,
  CashierDetailsDto,
  ClockOutData,
  Credentials,
  EmployeeCashBalance,
  EmployeeInfo,
} from 'typings/Cashier';
import { AxiosError, AxiosResponse } from 'axios';
import { isValidAxiosResponse } from 'typings/type-guards';
import { RootState } from 'stores';
import { getHybridModeCommunicator } from 'utils/hybridMode/hybridModeCommunicationUtils';
import { HybridModeMessages } from 'typings/HybridMode';
import { getAppInsights } from 'App/AppInitializationWrapper/AppInitializationWrapper';
import { getOnSiteSettings } from 'stores/Intake';

export const getClockedInUsers = createAsyncThunk('[CASHIER]/getClockedInUsers', async () => {
  const response = await posApi.get<EmployeeInfo[]>(posApiUrls.ACCOUNT_CLOCKED_IN_USERS, {
    headers: { Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  return response.data;
});

export const getLoggedInCashier = createAsyncThunk(
  '[CASHIER]/getLoggedInCashier',
  async (_, { rejectWithValue, dispatch }) => {
    const response = await posApi.get<CashierDetailsDto>(posApiUrls.USER_GET_DATA);
    if (isValidAxiosResponse(response)) {
      await dispatch(getClockedInUsers());
      return response.data;
    }

    return rejectWithValue(response);
  },
);

export const clockOutCashier = createAsyncThunk<AuthorizationDto, ClockOutData>(
  '[CASHIER]/clockOut',
  async ({ secret, employeeId }, { rejectWithValue }) => {
    const response = await posApi.post<AuthorizationDto>(posApiUrls.ACCOUNT_CLOCK_OUT_EMPLOYEE(employeeId), {
      Secret: secret,
    });
    if (isValidAxiosResponse(response)) {
      return response.data;
    }

    return rejectWithValue({ ...(response as AxiosError), employeeId });
  },
);

export const getEmployeeCashBalance = createAsyncThunk<EmployeeCashBalance | null, string>(
  '[CASHIER]/cashBalance',
  async (pinCode, { rejectWithValue, dispatch }) => {
    const response = await posApi.put<EmployeeCashBalance>(posApiUrls.ACCOUNT_EMPLOYEE_CASH_BALANCE(), {
      Secret: pinCode,
    });
    if (isValidAxiosResponse(response)) {
      if (response.data && response.data.requiresClockOutConfirmation) return response.data;

      dispatch(clockOutCashier({ secret: pinCode }));
      return null;
    }

    return rejectWithValue(response);
  },
);

export const clockInCashier = createAsyncThunk<AuthorizationDto, string>(
  '[CASHIER]/clockIn',
  async (pinCode, { rejectWithValue }) => {
    const response = await posApi.post<AuthorizationDto>(posApiUrls.ACCOUNT_CLOCK_IN, { Secret: pinCode });
    if (isValidAxiosResponse(response)) {
      return response.data;
    }

    return rejectWithValue(response);
  },
);

export const logInCashier = createAsyncThunk<AuthorizationDto, string, { state: RootState; rejectValue: unknown }>(
  '[CASHIER]/logIn',
  async (pinCode, { getState, dispatch, rejectWithValue }) => {
    const response = await posApi.post(posApiUrls.ACCOUNT_LOGIN, {
      Secret: pinCode,
    });
    if (isValidAxiosResponse(response)) {
      const state = getState();
      if (state.config.featureFlags.OfflineModule_SingleNavigation) {
        await dispatch(loginToStore(pinCode));
      }
      await dispatch(getClockedInUsers());
      await dispatch(getOnSiteSettings());
      dispatch(getLoggedInCashier());

      const hybridModeCommunicator = getHybridModeCommunicator();

      hybridModeCommunicator.send(HybridModeMessages.Hybrid.Events.CashierLoggedIn);

      return response.data as AuthorizationDto;
    }
    return rejectWithValue(response);
  },
);

export const automaticCashierLogOut = createAsyncThunk<void, void, { state: RootState; rejectValue: unknown }>(
  '[CASHIER]/automaticCashierLogOut',
  async (_, { getState, dispatch }) => {
    const {
      orderPayment,
      eft: { eftPaymentId },
    } = getState();
    if (eftPaymentId) {
      try {
        const appInsights = getAppInsights();
        appInsights.trackEvent({
          name: 'EFT unauthorized(401) api response during eft payment, skipping log out cashier',
        });
      } catch (er) {
        console.error('Could not push data to azure app insights:', er);
      }
      return;
    }

    if (orderPayment.orderId) {
      await dispatch(manualExitOrderPayment());
    }

    dispatch(logOutCashier());
  },
);

export const logOutCashier = createAsyncThunk<void, void, { state: RootState; rejectValue: unknown }>(
  '[CASHIER]/logOutCashier',
  async () => {
    await posApi.post<Credentials, AxiosResponse<AuthorizationDto>>(posApiUrls.ACCOUNT_LOGOUT);

    const hybridModeCommunicator = getHybridModeCommunicator();

    hybridModeCommunicator.send(HybridModeMessages.Hybrid.Events.CashierLoggedOut);
  },
);

export const loginToStore = createAsyncThunk<void, string>('[CASHIER]/loginToStore', async (pinCode, _) => {
  await posApi.get('/Online/Account/Switch', {
    withCredentials: true,
    headers: { 'X-Origin-Module': 'online-pos' },
  });

  await posApi.post(
    '/Online/Account/Switch',
    {
      ReturnUrl: '/Registry',
      PinCodeInput: pinCode,
      SwitchType: 'unlock',
    },
    {
      timeout: 10000,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Origin-Module': 'online-pos',
      },
    },
  );
});

export const startBreak = createAsyncThunk<void, void, { state: RootState; rejectValue: unknown }>(
  '[CASHIER]/startBreak',
  async (_, { dispatch }) => {
    const response = await posApi.post(posApiUrls.ACCOUNT_START_BREAK);
    if (isValidAxiosResponse(response)) {
      dispatch(logOutCashier());
    }
  },
);

export const endBreak = createAsyncThunk('[CASHIER]/endBreak', async (_, { dispatch }) => {
  const response = await posApi.put(posApiUrls.ACCOUNT_END_BREAK);
  if (isValidAxiosResponse(response)) {
    dispatch(getLoggedInCashier());
  }
});

export const revalidatePin = createAsyncThunk<
  { success: boolean; revalidateFor: string },
  { pinCode: string; revalidateFor: string }
>('[CASHIER]/revalidatePin', async ({ pinCode, revalidateFor }, { rejectWithValue }) => {
  const response = await posApi.put(posApiUrls.USER_REVALIDATE, {
    Secret: pinCode,
  });

  if (isValidAxiosResponse(response)) {
    return { revalidateFor, success: true };
  }

  return rejectWithValue(revalidateFor);
});
