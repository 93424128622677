import React from 'react';
import Typography from '@mui/material/Typography';
import { MenuItem, FormControl, Box } from '@mui/material';
import Select from '@mui/material/Select';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setFeatureFlag, setInstanceType, updateThemeKey } from 'stores/Config';
import { InstanceType } from 'stores/Config/configTypes';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { Environment } from 'services/ThemeService';
import FilledSwitch from 'components/Shared/Switch/FilledSwitch';
import EftDeveloper from 'App/DevMode/Eft/EftDeveloper';
import ScannerSimulator from 'App/DevMode/ScannerSimulator';

export default function DevModePanel(): JSX.Element {
  const dispatch = useAppDispatch();

  const { themeKey, featureFlags, instanceType } = useAppSelector((state) => state.config);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex' }}>
          <KeyboardIcon />
          <Typography variant="subtitle1">Ctrl + B = Open this toolbar</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <KeyboardIcon />
          <Typography variant="subtitle1">Ctrl + ArrowDown = Open local component toolbars</Typography>
        </Box>
      </Box>
      <ScannerSimulator />
      <EftDeveloper />
      <Box sx={{ mt: 2 }}>
        <Typography variant="h5">Theme</Typography>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <Select
            labelId="environment-key-label"
            id="environment-key"
            value={themeKey}
            onChange={(val) => {
              dispatch(updateThemeKey(val.target.value));
            }}
          >
            {Object.keys(Environment).some((env) => env === themeKey) ? null : (
              <MenuItem value={themeKey} key={themeKey}>
                {themeKey}
              </MenuItem>
            )}
            {Object.keys(Environment).map((env) => {
              return (
                <MenuItem value={env} key={env}>
                  {env}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h5">Instance type</Typography>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <Select
            labelId="instance-type-label"
            id="instance-type"
            value={instanceType}
            onChange={(val) => {
              dispatch(setInstanceType(val.target.value as InstanceType));
            }}
          >
            <MenuItem value="InStore" key="InStore">
              In Store
            </MenuItem>
            <MenuItem value="Central" key="Central">
              Central
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h5">Feature flags</Typography>
        <Box sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
          {Object.entries(featureFlags).map((entry) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Typography variant="body2">{entry[0]}</Typography>
                <FilledSwitch
                  size="small"
                  onChange={(_, checked): void => {
                    dispatch(setFeatureFlag({ key: entry[0], value: checked }));
                  }}
                  checked={entry[1] as boolean}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
}
