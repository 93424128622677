import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { AddressInputConfiguration, AddressSectionType } from 'stores/Config/configTypes';
import {
  CustomerSearchResultItem,
  OrderDeliveryAddress,
  ProfileAddress,
  OrderCustomerProfile,
} from 'stores/Intake';
import { mapProfileAddressToDeliveryAddress } from 'stores/Intake/IntakeStoreUtils';
import { Product } from 'typings/Products';
import { lowerCaseFirstLetter } from 'utils/orders/ordersUtils';

function canAddDeliveryCharge(deliveryCharge: number | null, deliveryType: PickUpTypesValues): number | null {
  if (deliveryType === PickUpTypesValues.delivery && deliveryCharge !== undefined) {
    return deliveryCharge;
  }
  return null;
}

export function getDeliveryAddress(
  selectedDeliveryAddress: ProfileAddress | undefined,
  manuallyFilledAddress: OrderDeliveryAddress | undefined,
): OrderDeliveryAddress | undefined {
  if (manuallyFilledAddress) {
    return manuallyFilledAddress;
  }

  return selectedDeliveryAddress ? mapProfileAddressToDeliveryAddress(selectedDeliveryAddress) : undefined;
}

export function mapToSelectedCustomer(searchResult: CustomerSearchResultItem): OrderCustomerProfile {
  return {
    emailAddress: searchResult.username,
    name: searchResult.firstname,
    surname: searchResult.lastname,
    telephoneNumber: searchResult.phoneNumber,
    profile: {
      id: searchResult.id,
      type: searchResult.profileType,
    },
    companyId: searchResult.companyId,
  } as OrderCustomerProfile;
}

export function isAddressComplete(
  deliveryAddress: OrderDeliveryAddress,
  addressInput: AddressInputConfiguration | undefined,
): boolean {
  if (!addressInput?.configurationElements) return true;

  const requiredFields = addressInput?.configurationElements.filter((el) => !el.isOptional);
  return !requiredFields.some(
    (field) => deliveryAddress[lowerCaseFirstLetter(field.sectionType) as AddressSectionType],
  );
}

export function isAddressValid(
  deliveryAddress: OrderDeliveryAddress | undefined,
  addressInput: AddressInputConfiguration | undefined,
): boolean {
  if (!deliveryAddress) return false;
  if (!addressInput?.configurationElements) return true;

  const requiredFields = addressInput?.configurationElements.filter((el) => !el.isOptional);
  const isAddressValid = !requiredFields.some((field) => {
    const fieldValue = deliveryAddress[lowerCaseFirstLetter(field.sectionType) as AddressSectionType];
    if (!fieldValue) return true;
    if (field.format) {
      const regex = RegExp(field.format);
      return !regex.test(fieldValue);
    }
    return false;
  });
  return isAddressValid;
}

export function editionMandatory(product: Product): boolean {
  if (!product) return false;
  if (product.isXTasty || product.isCYO) return true;
  if (product.hasToppingsSelectionSteps) return true;
  return false;
}

export default canAddDeliveryCharge;
