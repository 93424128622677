import React from 'react';
import { CheckCircle } from '@mui/icons-material';
import { Typography, useTheme } from '@mui/material';
import DisabledOverlay from 'components/Shared/DisabledOverlay/DisabledOverlay';
import buildClasses from './SingleSelectCard.css';

interface SingleSelectCardProps {
  active: boolean;
  selected: boolean;
  label: string;
  disabled?: boolean;
  price?: string;
  testId: string;
  onSelect(): void;
  center?: boolean;
}

const SingleSelectCard = ({
  active,
  disabled,
  selected,
  label,
  price,
  testId,
  onSelect,
  center = false,
}: SingleSelectCardProps) => {
  const { classes, cx } = buildClasses();
  const { palette, spacing } = useTheme();

  return (
    <div
      className={cx(classes.card, {
        [classes.active]: active,
        [classes.center]: center && !price,
      })}
      data-testid={testId}
      onClick={onSelect}
    >
      <Typography variant="subtitle2" sx={{ color: palette.black.main }}>
        {label}
      </Typography>
      {price && <Typography variant="body2">{price}</Typography>}
      {selected && <CheckCircle sx={{ position: 'absolute', top: spacing(0.5), right: spacing(0.5) }} />}
      <DisabledOverlay show={disabled} />
    </div>
  );
};

export default SingleSelectCard;
