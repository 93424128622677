import { createSlice } from '@reduxjs/toolkit';
import { LoyaltyState } from 'typings/Loyalty';
import { getAllRewards } from './loyalty.thunk-actions';

const initialState: LoyaltyState = {
  rewardTabs: [],
};

const loyaltySlice = createSlice({
  name: '[LOYALTY]',
  initialState,
  reducers: {
    clearRewards: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(getAllRewards.fulfilled, (state, action) => ({
      ...state,
      rewardTabs: action.payload?.rewardTabs ?? [],
    }));
  },
});

const { reducer } = loyaltySlice;
export const { clearRewards } = loyaltySlice.actions;

export default reducer;
