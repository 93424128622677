import React, { useEffect } from 'react';
import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ModalContainer from 'components/Shared/Modal/ModalContainer';

interface ClearBasketOnPhonecallDialogProps {
  open: boolean;
  phoneNumber?: string;
  onCallAccepted: (phoneNumberAccepted: string) => void;
  onCallIgnored: () => void;
}

const ClearBasketOnPhonecallDialog: React.FC<ClearBasketOnPhonecallDialogProps> = ({
  open,
  phoneNumber,
  onCallAccepted,
  onCallIgnored,
}) => {
  const { palette, spacing } = useTheme();
  const [t] = useTranslation('intake');
  const [phoneNumberPassed, setPhoneNumberPassed] = React.useState<string>();

  useEffect(() => {
    if (phoneNumber) {
      setPhoneNumberPassed(phoneNumber);
    }
  }, [phoneNumber]);

  if (!open) {
    return null;
  }

  function acceptCall() {
    if (phoneNumberPassed) {
      onCallAccepted(phoneNumberPassed);
    }
  }

  function ignoreCall() {
    onCallIgnored();
  }

  const showModal = open && phoneNumber !== undefined;

  return (
    <ModalContainer
      onClose={ignoreCall}
      open={showModal}
      title={t('Basket not empty')}
      primaryButton={{
        testId: 'accept-call-modal-confirm',
        label: t('Yes'),
        action: acceptCall,
      }}
      secondaryButton={{
        label: t('No'),
        testId: 'accept-call-modal-cancel',
        action: ignoreCall,
      }}
      testId="switch-to-online-modal"
    >
      <Typography
        variant="body1"
        sx={{
          color: palette.black.main,
          margin: spacing(2),
          textAlign: 'left',
        }}
      >
        {t('Phone call from:')} <b>{phoneNumberPassed}</b>
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: palette.black.main,
          margin: spacing(2),
          textAlign: 'left',
        }}
      >
        {t('Do you want to clear current basket and search for customer?')}
      </Typography>
    </ModalContainer>
  );
};
export default ClearBasketOnPhonecallDialog;
