import { CSSObject } from '@mui/material/styles';

import { BASE_THEME } from './base.theme.css';

const APA_COLORS = {
  ...BASE_THEME.palette,
  primary: {
    main: '#EC0020',
    light: '#ff1a39',
    dark: '#B31D21',
    contrastText: '#fff',
  },
  error: {
    ...BASE_THEME.palette.error,
    main: '#ac00e6',
    dark: '#6f0094',
    light: '#df80ff',
  },
};

export const APACHE = {
  ...BASE_THEME,
  palette: {
    ...APA_COLORS,
    virtualReceipt: {
      items: {
        discount: {
          ...BASE_THEME.palette.virtualReceipt.items.discount,
          error: {
            icon: 'rgb(255,41,41)',
            background: APA_COLORS.error.light,
          },
        },
      },
    },
  },
  components: {
    ...BASE_THEME.components,
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(16px, 12px) scale(1)',
        },
        shrink: {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
      },
    },
  },
};
